import React, { Component, Fragment } from 'react';
import initData from '../../../utils/initData';
import _ from 'lodash';
import { getLocalStorage } from '../../../utils/storageHelpers';
import SimpleSelect from '../Shared/SimpleDropDownSelect';
import plusIcon from '../../../assets/icons/icon_plus_big.svg';
import { isEmpty } from 'lodash'
import filtersTransformations, { filterGroupTypes } from '../../../utils/filterTransformations';
import lookup from 'country-code-lookup';
import { CircleFlag } from 'react-circle-flags';
import './StackedBarChart.scss';

const variableGroupToFirstOptionIndexMapping = {
  1: 1,
  2: 85,
  3: 89,
  4: 92,
  5: 117,
  6: 132,
  7: 133,
};

const disabledMainVariableFilterOptionsMap = {
  1: {},
  2: {
    86: [1, 2],
    87: [1, 2],
    88: [1, 2],
  },
  3: {
    90: [1, 2],
    91: [1, 2, 3, 4, 6],
  },
  4: {
    92: [2],
    93: [2],
    94: [2],
    95: [2],
    96: [2],
    97: [2],
    98: [2],
    99: [2],
    100: [2],
    101: [2],
    102: [2],
    103: [2],
    104: [2],
    105: [2],
    106: [2],
    107: [2],
    108: [2],
    109: [2],
    110: [2],
    111: [2],
    112: [2],
    113: [2],
    114: [2],
    115: [2],
    116: [2],
  },
  5: {
    117: [2],
    118: [2],
    119: [2],
    120: [2],
    121: [2],
    122: [2],
    123: [2],
    124: [2],
    125: [2],
    126: [2],
    127: [2],
    128: [2],
    129: [2],
    130: [2],
    131: [2],
  },
  6: {
    132: [1,2]
  },
};

const selectTypes = {
  main: 'main',
  group: 'group',
  filter: 'filter',
}

const largeColorPalette = ['#082734', '#103a47', '#184d5a', '#21626f', '#2a7884', '#338e99', '#3ca5af', '#46bcc6', '#f6c6fc', '#e69df2', '#c67fd9', '#a661c0', '#8844a4', '#6b2788', '#4d096a'];
const mediumColorPalette = ['#082734', '#1b5461', '#308692', '#46bcc6', '#db93ea', '#924dae', '#4d096a'];
const smallColorPalette = ['#082734', '#46bcc6', '#4d096a'];

class StackedBarChart extends Component {
  constructor(props) {
    super(props);

    this.filters = JSON.parse(getLocalStorage("filters"))?.filters;
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    document.querySelector('.dataItemsContainer').setAttribute('style', 'overflow: unset;')
  }

  componentWillUnmount() {
    document.querySelector('.dataItemsContainer').setAttribute('style', 'overflow-x: hidden;')
  }

  getColorPalette = (elementNumber) => {
    if(elementNumber <= 3) return smallColorPalette;
    else if(elementNumber <= 7) return mediumColorPalette;
    else return largeColorPalette;
  }

  getProfileCompGeoData(rowData) {
    return rowData.variables?.map(segVar => {
      if (segVar) {
        if (segVar.count > 0) {
          return segVar;
        }
      }
      return segVar;
    });
  }

  getFormattedData = () => {
    const { currentVariableFilter, data } = this.props;
    return data.map((rowData, index) => {
      const rowDictVariables = currentVariableFilter.option.propertyName || currentVariableFilter.option.isCompositeFilter ?
        data.filter(d => d.variables.length > 0)[0].variables.map((variable) => {
          const currentVariable = rowData.variables.find(segVar => segVar.name === variable.name);
          return !!currentVariable ? currentVariable : { name: variable.name, count: 0 };
        }) : this.getProfileCompGeoData(rowData);
 
      return {
        ...rowData,
        variables: rowDictVariables,
        charKey: String.fromCharCode(65 + index),
      }
    });
  }

  renderStatTooltip = (variable, currObj) => {
    let higherThanEl, lowerThanEl
    const getBrandElems = (item, index, arr) => {
      return (
        <Fragment key={index}>
          {index === 0 ? ' ' : index === arr.length - 1 ? ' and ' : ', '}
          <span>{item}</span>
        </Fragment>
      )
    }

    if (!isEmpty(currObj?.higherThan) && currObj?.higherThan[variable?.name]) {
      let higherThanArr = currObj?.higherThan[variable.name]
      if(this.props.currentVariableGroup !== 1){
        higherThanArr = higherThanArr.filter(el=> !(el === 'Age 18+' || el === 'Age 35+'))
      }
      higherThanEl = higherThanArr.map(getBrandElems)
    }
    if (!isEmpty(currObj?.lowerThan) && currObj?.lowerThan[variable.name]) {
      let lowerThanArr = currObj.lowerThan[variable.name]
      if(this.props.currentVariableGroup !== 1){
        lowerThanArr = lowerThanArr.filter(el=> el !== 'Age 18+' || el !== 'Age 35+')
      }
      lowerThanEl = lowerThanArr.map(getBrandElems)
    }

    return (
      <>
        {higherThanEl?.length > 0 && (<div className={'statTooltip'}><span>{currObj.inputName}</span> is statistically significantly higher than {higherThanEl}</div>)}
        {lowerThanEl?.length > 0  && (<div className={'statTooltip'}><span>{currObj.inputName}</span> is statistically significantly lower than {lowerThanEl}</div>)}
      </>
    )
  }

  renderStatLetters = (variable, currObj, data) => {
    if (!isEmpty(currObj?.higherThan) && currObj?.higherThan[variable?.name]) {
      let higherThanArr = currObj.higherThan[variable.name];
      const higherThanEl = [];
      if(this.props.currentVariableGroup !== 1){
        higherThanArr = higherThanArr.filter(el=> !(el === 'Age 18+' || el === 'Age 35+'))
      }
      higherThanEl.push(
        <div className={'statLetter'}>
          <span>{data.find(elem => elem.inputName === higherThanArr[0])?.charKey}</span>
        </div>
      );

      if (higherThanArr.length === 2) {
        higherThanEl.push(
          <div className={'statLetter'}>
            <span>{data.find(elem => elem.inputName === higherThanArr[1])?.charKey}</span>
          </div>
        );
      } else if (higherThanArr.length > 2) {
        higherThanEl.push(
          <div className={'statLetter'}>
            <span>+{higherThanArr.length - 1}</span>
          </div>
        );
      }

      return higherThanEl;
    }

    return [];
  }

  isGeoGeneralDataRow(row) {
    const { chartType, currentVariableMainFilter } = this.props;

    if (chartType === 'geo' && (row.displayName === 'General Population' || row.name === 'General Population') && currentVariableMainFilter !== 0)
      return true;

    return false;
  }

  renderRows = () => {
    const {
      rowsData,
      currentVariableFilter,
      data,
      chartType,
      currentVariableGroup,
      statTestingEnabled,
      page
    } = this.props;
    const diagramWidth = document.documentElement.clientWidth - 390;
    const titleHideWidth = 40;
    const diagramTooltipWidth = 200;

    let formattedData = this.getFormattedData();
    let rows = [];
    let chartLegendIndex = 0;

    if(chartType === 'segments') {
      let age18Segment = rowsData.findIndex(item => item.segmentName === "Age 18+" || item.segmentName === 'Age 35+')
      if (rowsData && rowsData.length > 0 )  {
        rowsData.unshift(rowsData.splice(age18Segment, 1)[0])
      }
      rows = rowsData.map(row => {
        const filterLabels = [];
        row.filters.forEach(filter => {
          let fullFilter = this.filters?.find(f => f.id === filter.filterId);

          if (fullFilter?.type === filterGroupTypes.FILTER_GROUP) {
            fullFilter = filtersTransformations.transformTypeFromFilterGroupIntoOption(fullFilter);
          }

          if(filter.filterOptions) {
            filter.filterOptions.forEach(filterProp => {
              let fo = fullFilter?.filterOptions.find(fo => fo.id === filterProp);
              if(!fo) {
                fo = fullFilter?.filterOptions.find(fo => (fo.groupingIds && fo.groupingIds.some(id => id === filterProp)));
              }
              if(fo) {
                filterLabels.push(initData.getFilterOptionLabel(filtersTransformations.createFilterOptionKey(fullFilter.name, fo.value), 0));
              }
            })
          }
        });
        const uniqueFilterLabels = _.uniqBy(filterLabels, function (e) {
          return e;
        });

        return {
          name: row.segmentName,
          id: row.segmentId,
          filterLabels: uniqueFilterLabels,
          active: row.isActive,
        }
      
      });
    } else if(chartType === 'competitive' || 'geo') {
      rows = rowsData.map((row) => ({...row, ...formattedData.find(obj=>obj.inputId === (row?.id?row.id:row.inputId))}));
    }
    if((['competitive','geo','segments'].indexOf(chartType) !== -1 && currentVariableGroup !== 1) || (rows.length !== formattedData.length && currentVariableGroup !== 1)) {
      // let age18Index = rowsData.findIndex(item => item.id === -1)
      // if(age18Index !== -1){
      //   formattedData=formattedData.filter(item => item.inputId != -1)
        // rows=rows.filter(item => item.inputId != -1) 

      // }
      rows.splice(0, 1);
    }
    
    if (formattedData && formattedData.length > 0) {
        formattedData.sort((a, b) => {
          const orderA = rows.findIndex(item => (item?.id?item?.id:item.inputId) === a.inputId);
          const orderB = rows.findIndex(item => (item?.id?item?.id:item.inputId) === b.inputId);
          return orderA - orderB;
        });
    }
    return (
      <>
        {!!data.length && !!rows.length && rows.map((row, rowIndex) => {
          const isntGeneralPopRow = this.isGeoGeneralDataRow(row);
          if (isntGeneralPopRow) {
            chartLegendIndex = 1;
            return;
          }
        let countryCode = lookup.countries.filter(obj=>(obj.country === row.inputName || obj.internet === row.countryCode))
        let selectedIndex = formattedData.findIndex(({inputId})=> inputId === (row?.id?row?.id:row.inputId));
        return row.active === false ? null : selectedIndex> -1 && (
            <div className={'barChartRow'} key={row.id}>
              <div className={'titleBlock'}>
                <div>
                  <div className={'logoTitleHolder'}>
                    {
                      row.logo && chartType !== 'segments' ? <img src={row.logo} className="brandLogo" alt={"logo" + rowIndex} /> :
                           (chartType === 'geo' && countryCode[0]?.internet) ?
                              <CircleFlag countryCode={countryCode[0]?.internet.toLowerCase()} height="25" style={{paddingRight:'10px'}}/>:
                              (<div className="brandLogo textLogo">
                                <div>{(row.displayName || row.name || row.inputName)?.slice(0, 1)?.toUpperCase()}</div>
                              </div>) 
                              
                    }
                    <div className={'titleContainer'}>
                      <div className={'title'}>{chartType === 'geo' ? (row.displayName === 'General Population' || row.name === 'General Population') ?
                          'All Markets' :
                          <div className={"rowTitle"}>
                            <span className={"campaignName"}>{row.inputName}</span>
                            <span className={"campaignRegion"}>{row.region}</span>
                          </div>
                          :
                          <div className={'title'}>{(row.displayName || row.name)} 
                            {(row.displayName || row.name).length>17 &&<span className={'tooltiptext'}>{(row.displayName || row.name)}</span>}</div>}
                          </div>
                      <div className={'blockValue'}>{`n=${Number(formattedData[selectedIndex]?.pool && formattedData[selectedIndex].pool?.toFixed(0)).toLocaleString('en-En')}`}</div>
                    </div>
                  </div>
                  {statTestingEnabled && (
                      <div className={'statLetter'}>
                        <span>{formattedData[selectedIndex]?.charKey}</span>
                      </div>
                  )}

                  {!!row.filterLabels && !!row.filterLabels.length &&
                  <div className={'titleTooltip'}>
                    {row.filterLabels.map(label => (<div key={label} className={'titleTooltipLabel'}>{label}</div>))}
                  </div>
                  }
                </div>
              </div>
              <div className={'chartBlock'}>
                <div className={'diagramBlock'}>
                  {formattedData[selectedIndex]?.variables?.map((variable, index, variableArray) => {
                    const splitNameArr = variable.name.toLowerCase().split('_');
                    const slicedVarName = !!splitNameArr.length ? splitNameArr[splitNameArr.length - 1] : '';
                    const variableLabel = slicedVarName === 'yes' || slicedVarName === 'no' ? slicedVarName :
                        initData.getFilterOptionLabel(filtersTransformations.createFilterOptionKey(currentVariableFilter.option.fullName, variable.name), 0);
                    const variableName = variableLabel.toLowerCase() === 'no name' ? variable.name : variableLabel;
                    const countPercent = variable.percent.toFixed(1);
                    const resultPercent = countPercent <= 0.1 ? 0 : countPercent < 1 ? 1 : countPercent;
                    const isTitleVisible = (countPercent/100 * diagramWidth) > titleHideWidth;
                    const isTooltipCentered = (countPercent/100 * diagramWidth) > 150;
                    const itemColor = this.getColorPalette(variableArray.length)[index];
                    const statLetters = this.renderStatLetters(variable, formattedData[selectedIndex], formattedData)
                    const isStatLettersVisible = (countPercent/100 * diagramWidth) > (titleHideWidth + statLetters.length * 30)
                    const absoluteValue = (Math.round(Number(variable.count)) >= 1 ? Math.round(Number(variable.count)) : '<1').toLocaleString('en-En');
                    
                    return (
                      <div className={'diagramItemBlock'} style={{width: `${resultPercent}%`}} key={index}>
                        <div className={'diagramTooltip'}
                          style={
                            index === formattedData[selectedIndex]?.variables?.length - 1 && !isTooltipCentered ?
                            {right: 0} :
                            {left: (diagramWidth/100*countPercent/2 - diagramTooltipWidth/2) || 0}
                          }
                        >
                          <div>
                            <div className={'diagramTooltipTitle'}>{row.displayName || row.name || row.inputName}</div>
                            <div className={'valuesBlock'}>
                              <div className={'percentValue'}>{`${countPercent}%`}</div>
                              <div className={'filterName'}>{variableName}</div>
                              <div
                                  className={'absoluteValue'}
                                  style={{color: itemColor}}
                              >
                                {absoluteValue}
                              </div>
                            </div>
                          </div>

                          {statTestingEnabled && this.renderStatTooltip(variable, formattedData[selectedIndex])}
                        </div>
                        {selectedIndex === chartLegendIndex && isTitleVisible &&
                        <div className={`itemTitleBlock`} style={{color: itemColor}}>
                          <div className={'itemTitle'}>{variableName}</div>
                        </div>}
                        <div className={`diagramItem`} key={variableName} style={{backgroundColor: itemColor}}>
                          {isTitleVisible && `${countPercent}%`}
                          {isStatLettersVisible && statTestingEnabled && statLetters}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  selectNextOptionWhenMainFilterDisabled = async (e) => {
    const {currentVariableMainFilter, currentVariableGroup, onSelectVariableMainFilter} = this.props;
    const disabledList = disabledMainVariableFilterOptionsMap[currentVariableGroup][e] || [];
    if(disabledList?.includes(currentVariableMainFilter)) {
      const filterOptionsId = [1,2,3,4,5,6];
      const findNextEle = filterOptionsId?.find((item) => !disabledList.includes(item))
      await onSelectVariableMainFilter(findNextEle);
    }
  }

  render() {
      const {
        onClickFilterFunction,
        onSelectVariableMainFilter,
        onSelectVariableFilter,
        onSelectVariableGroup,
        currentVariableFilter,
        currentVariableGroup,
        currentVariableMainFilter,
        mainVariableFilterOptions,
        variableFilterOptions,
        variableGroupOptions,
        variableOptionOpen,
        setVariableOptionOpen,
        chartType,
        data,
      } = this.props;
      let formattedData = this.getFormattedData();
      let disabledMainVariableFilterOptions = [];
      //if selected option is recommended then defaultly set to usebrand filters
      if(disabledMainVariableFilterOptionsMap?.[currentVariableGroup]?.[currentVariableFilter.index]) {
        disabledMainVariableFilterOptions = disabledMainVariableFilterOptionsMap?.[currentVariableGroup]?.[currentVariableFilter.index];
      }
      return (
        <div className={'stackedBarChart'}>
          <div className={'topContainer'}>
              <div className={'selectContainer'}>
                  <div className={'mainSelectContainer'}>
                    <SimpleSelect
                      data={mainVariableFilterOptions}
                      selected={currentVariableMainFilter}
                      open={variableOptionOpen === selectTypes.main}
                      placeholder="" skey="c_"
                      onOpenSelect={() => setVariableOptionOpen(selectTypes.main)}
                      onSelectFunction={onSelectVariableMainFilter}
                      disabledOption={disabledMainVariableFilterOptions}
                    />
                  </div>
                  <div className={'secondarySelectContainer'}>
                    <SimpleSelect
                      data={variableGroupOptions}
                      selected={currentVariableGroup}
                      open={variableOptionOpen === selectTypes.group}
                      placeholder="" skey="c_"
                      onOpenSelect={() => setVariableOptionOpen(selectTypes.group)}
                      onSelectFunction={async (e) => {
                        await onSelectVariableGroup(e);
                        await this.selectNextOptionWhenMainFilterDisabled(variableGroupToFirstOptionIndexMapping[e]);                        
                      }}
                    />
                    <SimpleSelect
                      data={variableFilterOptions}
                      selected={currentVariableFilter.index}
                      open={variableOptionOpen === selectTypes.filter}
                      placeholder=""
                      skey="c_"
                      onOpenSelect={() => setVariableOptionOpen(selectTypes.filter)}
                      onSelectFunction={async (e) => {
                        await onSelectVariableFilter(e);
                        await this.selectNextOptionWhenMainFilterDisabled(e);
                      }}
                      wider={true}
                    />
                  </div>
              </div>
              <div className={'optionList'}>
                {formattedData && formattedData[0] && formattedData[0].variables?.map((variable, index, variableArray) => {
                  const splitNameArr = variable.name.toLowerCase().split('_');
                  const slicedVarName = !!splitNameArr.length ? splitNameArr[splitNameArr.length - 1] : '';
                  const variableLabel = slicedVarName === 'yes' || slicedVarName === 'no' ? slicedVarName :
                    initData.getFilterOptionLabel(filtersTransformations.createFilterOptionKey(currentVariableFilter.option.fullName, variable.name), 0);
                  const variableName = variableLabel.toLowerCase() === 'no name' ? variable.name : variableLabel;
                  const itemColor = this.getColorPalette(variableArray.length)[index];
                  return (
                    <div className={'optionItem'} key={variable.name}>
                      <div className={`circleMarker`} style={{backgroundColor: itemColor}}/>
                      <div>{variableName}</div>
                    </div>
                  )
                })}
              </div>

          </div>
          <div className={'barChartArea'}>
            {!!data && !!data.length && <>
              {this.renderRows()}
              {chartType === 'segments' && <div className={'barChartRow buttonContainer'}>
                <div className={'titleBlock'}>
                  <div className="iconContainer" onClick={onClickFilterFunction}>
                    <img className="plusIcon" src={plusIcon} alt="plus icon" height={25} />
                  </div>
                </div>
              </div>}
            </>}
          </div>
        </div>
      )
  }
}

export default StackedBarChart;
