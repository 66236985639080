import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEmpty } from 'lodash';
import LoadingLayer from '../widgets/Shared/LoadingLayer';
import BarChart from '../widgets/ChartComponents/BarChart';
import EquityCard from '../widgets/ChartComponents/EquityCard';
import EmotionalCard from '../widgets/ChartComponents/EmotionalCard';
import FunnelChartCard from '../widgets/ChartComponents/FunnelChartCard';
import LineChart from '../widgets/ChartComponents/LineChart';
import AdvertisingCard from '../widgets/ChartComponents/AdvertisingCard';
import initData from '../../utils/initData';
import ApiData from '../../utils/Api';
import StackedBarChart from '../widgets/ChartComponents/StackedBarChart';
import './CompetitiveIntelligenceHomeComponent.scss';
import TwoByTwoChart from '../widgets/ChartComponents/TwoByTwoChart';
import transformHigherLowerThan from '../../utils/transformHigherLowerThan';
import {
    setBrands as setTwoByTwoBrands,
    setChartData as setTwoByTwoChartData,
    clearAllData as clearAllTwoByTwoData,
} from '../../reducers/TwoByTwoChart/actionCreators';
import {
    setChartData as setTrendsData,
    clearAllData as clearAllTrendsData,
} from '../../reducers/Trends/actionCreators';
import EquityScrollWrapperHeightService, { selectors } from '../../utils/equityScrollWrapperHeightService';
import TwoByTwoChartAxisPatchService from '../../utils/2x2ChartAxisPatchService';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getLocalStorage, setLocalStorage } from '../../utils/storageHelpers';
import filtersTransformations from '../../utils/filterTransformations';
import moment from 'moment';
import dateTransform from '../../utils/dateTransform';
import { safeParseJSONSessionStorage } from '../../utils/getBrowserStorageData';
import { getCompetitiveSelectedMarketAndBrands } from '../../utils/URLParamsValidations';
import { validationsForHSSMarketLabels } from '../../utils/common';

class CompetitiveIntelligenceHomeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            brandData:[],
            loading: true,
            readyShow: true,
            variableData: [],
            scrollWrapperService: null,
            scrollWrapperHeight: null,
        }
        this.debounceTimer = false;
        this.twoByTwoChartAxisPatchService = new TwoByTwoChartAxisPatchService();
        this.wrapperRef = React.createRef();
        this.props.setRef(this.wrapperRef);
    }

    updateListData = (listData) => {
        const {
            setTrendsData,
            selectedTab,
            brands,
            interval
        } = this.props;
        // listData items structure for Trends is slightly different
        const notTrendsTab = selectedTab !== 4;
        this.props.setDataAvailability(listData.every(item => notTrendsTab ? !isEmpty(item.data.data) : !isEmpty(item.data)))
        this.setState({
            loading: false,
            readyShow: true,
            brandData: listData,
        });
        if (!notTrendsTab) {
            listData.sort((a, b) => {
                const orderA = brands.findIndex(item => item.id === a.brand.id);
                const orderB = brands.findIndex(item => item.id === b.brand.id);
                return orderA - orderB;
            });
            setTrendsData(dateTransform(listData,interval));
        }
    }

    getDefaultListData = (tab, defaultData) => {
        const bcolors = initData.getColors().map(color => color[0])
        let brandColor;
        return this.props.brands.map((item, index) => {
            if(!item.color){
                brandColor = bcolors[index%10]
               }else{
                brandColor = item.color
               }
            return {
                tab: tab,
                brand: item,
                color: item.brandUpdation==='yes'?item.color:brandColor,
                data: defaultData,
            }
        })
    }

    getDefaultTrendsData = (stab,brands) => {
        let finalData=[];
        brands.forEach((obj,ind)=>finalData.push({ tab: 4, stab: stab, brand: brands[ind], color:brands[ind].color , brandShow: false, data: []}))
        return finalData;
    }

    getXYZfor2x2 = () => {
        const mapFunc = filter => {
            let filterOptions = [];
            filter.filterOptions.forEach(fo => {
                if(fo.type === 'group' && fo.groupingIds) {
                    filterOptions = [...filterOptions, ...fo.groupingIds];
                } else {
                    filterOptions = [...filterOptions, fo.id];
                }
            });
            return { filterId: filter.id, filterOptions};
        };

        const {
            xAxisSelect,
            yAxisSelect,
            zAxisSelect,
        } = this.props;
        let x = get(xAxisSelect, 'subcategory.filters', []).map(mapFunc);
        let y = get(yAxisSelect, 'subcategory.filters', []).map(mapFunc);
        let z = get(zAxisSelect, 'subcategory.filters', []).map(mapFunc);

        // Size works incorrect if payload is an empty array
        x = x.length ? this.twoByTwoChartAxisPatchService.patchAxisPayload(xAxisSelect, x) : null;
        y = y.length ? this.twoByTwoChartAxisPatchService.patchAxisPayload(yAxisSelect, y) : null;
        z = z.length ? this.twoByTwoChartAxisPatchService.patchAxisPayload(zAxisSelect, z) : null;

        return { x, y, z };
    };

    getTendsOptionsData = (trendsData)=>{
        this.setState({trendsDataName: trendsData});
    }

    groupBy =(arr, property) =>{
        return arr.reduce(function(memo, x) {
            if (!memo[x[property]]) { memo[x[property]] = []; }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    getListData = async() => {
        const {
            brands,
            currentVariableGroup,
            currentVariableFilter,
            interval,
            selectedTab,
            useExposed,
            selectedCampaign,
            selectedDate,
            lineOption,
            currentVariableMainFilter,
            selectedPeriod,
            segments,
            page,
            getAppliedFilters
        } = this.props;
        let optionModes = initData.getModes();
        let startDate = selectedDate.startdate;
        if(((selectedTab === 3 )||(selectedTab === 4 && lineOption.stab === 3)||(selectedTab === 5 && currentVariableGroup === 5)) && (selectedPeriod === 1 && moment(startDate).isBefore('2022-09-21'))){
            startDate = '2022-09-21'
        }
        let searchFilter = getAppliedFilters();
        let selectedMode = optionModes.filter(obj=> obj.id === useExposed)[0]?.name?.toUpperCase().replace(/ /g, "");
        let selectedFilters = filtersTransformations.applyMarketFilters(searchFilter, segments, selectedCampaign);
        let selectedURLCIBrands = safeParseJSONSessionStorage('userSelectedBrands')?.find(({marketId})=> marketId == selectedCampaign?.marketId)?.brands;
        selectedURLCIBrands = brands?.filter(ele => selectedURLCIBrands?.some(id => id == ele.id));
        let brandIds = selectedURLCIBrands.map(brandObj=>brandObj.id);
        brandIds = brandIds?.length>0 ? brandIds:brands?.map(({id})=>id);

        let body = {
            marketId: selectedCampaign.marketId,
            brandIds: brandIds,
            fromDate: startDate,
            toDate: selectedDate.enddate,
            filters: selectedFilters[0]?.filters,
            segments: selectedFilters[0]?.segments,
            globalBrandEngagement: selectedMode,
            module: initData.getModuleType(page),
            isStatTestingEnabled:true
        }
        if (selectedTab === 0) {
            try {
                let result = await ApiData.getGeoEquityData(body)
                this.updateListData(
                    result.map((item, index) => {
                        const data = {
                            value: Number(item.equity?.toFixed(1)),
                            upDown: Number(item.equityDiff?.toFixed(1)),
                            pool: Number(item.pool?.toFixed(2)),
                            data: [
                                {label: "Momentum", value: Number(item.netMomentum?.toFixed(1)), diff: Number(item.netMomentumDiff?.toFixed(1))},
                                {label: "Consideration", value: Number(item.consideration?.toFixed(1)), diff: Number(item.considerationDiff?.toFixed(1))},
                                {label: "Quality", value: Number(item.highQuality?.toFixed(1)), diff: Number(item.highQualityDiff?.toFixed(1))},
                                {label: "Familiarity", value: Number(item.familiarity?.toFixed(1)), diff: Number(item.familiarityDiff?.toFixed(1))}
                            ]
                        }
                        return {
                            tab: 0,
                            brand: brands.find(elem => elem.id === item.inputId),
                            color: brands.find(elem => elem.id === item.inputId)?.color,
                            data: data,
                            lowerThan: transformHigherLowerThan(item.lowerThan),
                            higherThan: transformHigherLowerThan(item.higherThan),
                        }
                    })
                )
            } catch(err) {
                console.log("error" + err )
                this.updateListData(this.getDefaultListData(0, { sample: 0, value: 0, upDown: 0, data: [] }))
            }
        }

        if (selectedTab === 1) {
            try {
                 body.globalBrandEngagement = "GENPOP";
                let result = await ApiData.getGeoConversionData(body)
                this.updateListData(
                    result.map((item, index) => {
                        const data = [
                            {label: 'Awareness', value: Number(item.awareness?.toFixed(1)), percentage: Number(item.awarenessPercent?item.awarenessPercent?.toFixed(1):0), key: 'awareness'},
                            {label: 'Familiarity', value: Number(item.familiarity?.toFixed(1)), percentage: Number(item.familiarityPercent?item.familiarityPercent?.toFixed(1):0), key: 'familiarity'},
                            {label: 'Trial', value: Number(item.trial?.toFixed(1)), percentage: Number(item.trialPercent?item.trialPercent?.toFixed(1):0), key:'trial'},
                            {label: 'Usage', value: Number(item.usage?.toFixed(1)), percentage: Number(item.usagePercent?item.usagePercent?.toFixed(1):0), key:'usage'},
                            {label: 'Recommend', value: Number(item.recommend?.toFixed(1)), percentage: Number(item.recommendPercent?item.recommendPercent?.toFixed(1):0), key:'recommend'}
                        ]
                        data.sort((a,b) => {return b.value - a.value})
                        return {
                            tab: 1,
                            brand: brands.find(elem => elem.id === item.inputId),
                            color: brands.find(elem => elem.id === item.inputId)?.color,
                            data: { pool: Number(item.pool?.toFixed(2)), data: data },
                            lowerThan: item.lowerThan,
                            higherThan: item.higherThan,
                        }
                    })
                )
            } catch(err) {
                console.log("error=" + err )
                this.updateListData(this.getDefaultListData(1, { pool: 0, data:[] }))
            }
        }

        if (selectedTab === 2) {
            try {
                let result = await ApiData.getGeoImageryData(body)
                this.updateListData(
                    result.map((item, index) => {
                        const data = validationsForHSSMarketLabels(item, selectedCampaign?.countryCode);

                        return {
                            tab: 2,
                            brand: brands.find(elem => elem.id === item.inputId),
                            color: brands.find(elem => elem.id === item.inputId)?.color,
                            data: { pool: Number(item['pool']?.toFixed(2)), data: data },
                            lowerThan: item.lowerThan,
                            higherThan: item.higherThan,
                        }
                    })
                )
            } catch(err) {
                console.log("error=" + err )
                this.updateListData(this.getDefaultListData(2, { pool: 0, data:[] }))
            }
        }

        if (selectedTab === 3) {
            try {
                let result = await ApiData.getGeoAdvertisingData(body)
                this.updateListData(
                    result.map((item, index) => {
                        const data = validationsForHSSMarketLabels(item, selectedCampaign?.countryCode);
                        
                        return {
                            tab: 3,
                            brand: brands.find(elem => elem.id === item.inputId),
                            color: brands.find(elem => elem.id === item.inputId)?.color,
                            data: { pool: Number(item['pool']?.toFixed(2)), upDown: Number(item.advertisingRecallDiff?.toFixed(1)), upDownNA: (item.advertisingRecallDiff === null), data: data },
                            lowerThan: item?.lowerThan,
                            higherThan: item?.higherThan,
                        }
                    })
                )
            } catch(err) {
                console.log("error=" + err )
                this.updateListData(this.getDefaultListData(3, { pool: 0, upDown: 0, upDownNA: true, data:[] }))
            }
        }
        if(selectedTab === 4){
            let body = {
                marketId:selectedCampaign.marketId,
                brandIds:brandIds,
                fromDate:startDate,
                toDate:selectedDate.enddate,
                intervalUnit: interval,
                filters: selectedFilters[0]?.filters,
                segments: selectedFilters[0]?.segments,
                globalBrandEngagement: selectedMode,
                module: initData.getModuleType(page),
                isStatTestingEnabled:true
            }
            
            if(lineOption.stab === 0){
                ApiData.getGeoEquityData( body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map(obj=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                    return data.push({
                                        date: r.date,
                                        pool: Number(r.pool?r.pool.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal.toFixed(2)),
                                        total:Number(r.equity?r.equity.toFixed(1):0),
                                        momentum: Number(r[lineOption?.selectedScoreOption+'NetMomentum']?r[lineOption.selectedScoreOption+'NetMomentum'].toFixed(1):0),
                                        consideration: Number(r[lineOption?.selectedScoreOption+'Consideration']?r[lineOption.selectedScoreOption+'Consideration'].toFixed(1):0),
                                        quality: Number(r[lineOption?.selectedScoreOption+'HighQuality']?r[lineOption.selectedScoreOption+'HighQuality'].toFixed(1):0),
                                        familiarity: Number(r.familiarity?r.familiarity.toFixed(1):0),
                                        lowerThan: r?.lowerThan,
                                        higherThan: r?.higherThan,
                                    })
                                })
                            return  finalData.push({ tab: 4, stab: 0, brand: brands.filter(item=>item.id == obj)[0], color:brands.filter(item=>item.id == obj)[0].color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 0;
                            this.updateListData(this.getDefaultTrendsData(stab ,brands)); 
                        }
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        let stab = 0;
                        this.updateListData(this.getDefaultTrendsData(stab ,brands));
                    }
                )
            }
            if(lineOption.stab === 1){
                ApiData.getGeoConversionData( body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map(obj=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                return  data.push({
                                        date: r.date,
                                        pool: Number(r.pool?r.pool.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal?.toFixed(2)),
                                        awareness: Number(r.awareness?r.awareness.toFixed(1):0),
                                        familiarity: Number(r.familiarity?r.familiarity.toFixed(1):0),
                                        trial: Number(r.trial?r.trial.toFixed(1):0),
                                        usage: Number(r.usage?r.usage.toFixed(1):0),
                                        recommend: Number(r[lineOption?.selectedScoreOption+'Recommend']?r[lineOption.selectedScoreOption+'Recommend'].toFixed(1):0),
                                        lowerThan: r?.lowerThan,
                                        higherThan: r?.higherThan,
                                    });
                                });
                            return  finalData.push({ tab: 4, stab: 1, brand: brands.filter(item=>item.id == obj)[0], color:brands.filter(item=>item.id == obj)[0].color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 1;
                            this.updateListData(this.getDefaultTrendsData(stab ,brands));
                        }
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        let stab = 1;
                        this.updateListData(this.getDefaultTrendsData(stab ,brands));
                    }
                );
            }
            if(lineOption.stab === 2){
                ApiData.getGeoImageryData(body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map(obj=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                    const rdata = {
                                        date: r.date,
                                        pool: Number(r.pool?r.pool.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal?.toFixed(2)),
                                        lowerThan: r.lowerThan,
                                        higherThan: r.higherThan,
                                    };
                                    for (const key in r) {
                                        if (key !== 'date' && key !== 'pool' && key !== 'higherThan' && key !== 'lowerThan' && key !== 'inputName' && key !== 'inputId' && key !=='genpop' && key !== 'newDate') {
                                            rdata[key] = Number(r[key]?r[key].toFixed(1):0);
                                        }
                                    }
                                    data.push(rdata);
                                });
                            return finalData.push({ tab: 4, stab: 2, brand: brands.filter(item=>item.id == obj)[0], color:brands.filter(item=>item.id == obj)[0].color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 2;
                            this.updateListData(this.getDefaultTrendsData(stab ,brands)); 
                        }
                    }                        
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        let stab = 2;
                        this.updateListData(this.getDefaultTrendsData(stab ,brands));                        
                    }
                );
            }
            if(lineOption.stab === 3){
                ApiData.getGeoAdvertisingData(body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map(obj=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                    const rdata = {
                                        date: r.date,
                                        pool: Number(r.pool?r.pool.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal?.toFixed(2)),
                                        lowerThan: r.lowerThan,
                                        higherThan: r.higherThan,
                                    };
                                    for (const key in r) {
                                        if (key !== 'date' && key !== 'pool' && key !== 'higherThan' && key !== 'lowerThan' && key !== 'inputName' && key !=='genpop' && key !== 'newDate') {
                                            rdata[key] =  Number(r[key]?r[key].toFixed(1):0);
                                        }
                                    }
                                    data.push(rdata);
                                });
                            return  finalData.push({ tab: 4, stab: 3, brand: brands.filter(item=>item.id == obj)[0], color:brands.filter(item=>item.id == obj)[0].color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 3;
                            this.updateListData(this.getDefaultTrendsData(stab ,brands)); 
                        }
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        let stab = 3;
                        this.updateListData(this.getDefaultTrendsData(stab ,brands));                        
                    }
                );
            }
            if(lineOption.stab === 4){
                ApiData.getGeoIrreplaceabilityData( body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map(obj=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                    return data.push({
                                        date: r.date,
                                        pool: Number(r.pool?r.pool.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal.toFixed(2)),
                                        total:Number(r.equity?r.equity.toFixed(1):0),
                                        positive: Number(r.positive? r.positive.toFixed(1):0),
                                        neutral: Number(r.neutral?r.neutral.toFixed(1):0),
                                        negative: Number(r.negative? r.negative.toFixed(1):0),
                                        net: Number(r.net?r.net.toFixed(1):0),
                                        lowerThan: r?.lowerThan,
                                        higherThan: r?.higherThan,
                                    })
                                })
                            return  finalData.push({ tab: 4, stab: 4, brand: brands.filter(item=>item.id == obj)[0], color:brands.filter(item=>item.id == obj)[0].color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 4;
                            this.updateListData(this.getDefaultTrendsData(stab ,brands)); 
                        }
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        let stab = 4;
                        this.updateListData(this.getDefaultTrendsData(stab ,brands));
                    }
                )
            }
            
        }

        if(selectedTab === 5) {
            // this.setState({ variableData: [] });
            let payload = {
                marketId: selectedCampaign.marketId,
                brandIds: brandIds,
                fromDate: startDate,
                toDate: selectedDate.enddate,
                filters: selectedFilters[0]?.filters,
                segments: selectedFilters[0]?.segments,
                globalBrandEngagement: selectedMode,
                profileGlobalBrandEngagemntId:currentVariableMainFilter,
                profileFilterId:currentVariableGroup,
                profileFilterOptionId:currentVariableFilter.index,
                isAge18:true,
                module: initData.getModuleType(page),
                isStatTestingEnabled:true
            }
            ApiData.getGeoCompetitiveVariableData(payload).then(
                (result) => {
                    if(currentVariableGroup !== 1){
                        result = result.filter(val=>Number(val.inputId) !== -1)
                    }
                    this.props.setDataAvailability(!isEmpty(result))
                    this.setState({
                        loading: false,
                        readyShow: true,
                        variableData: result,
                    });
                }
            ).catch(
                (err) => {
                    console.log("error=" + err );
                    this.props.setDataAvailability(false)
                    this.setState({loading: false});
                }
            );
            return;
        }
    }

    async runQueuedPromises(promises) {
        const MAX_REQUESTS_AT_TIME = 4;
        const results = [];

        while (promises.length) {
            await Promise.all(promises.splice(0, MAX_REQUESTS_AT_TIME).map(f => f().then(list => results.push(list))));
        }

        return results;
    }

    componentDidMount() {
        const { brands, setTwoByTwoBrands } = this.props;
        setTwoByTwoBrands(brands);
        this.initializeAndUseScrollWrapperService();
        window.addEventListener('resize', this.onResize);
    }

    componentDidUpdate(prevProps) {
        const serviceInitialized = Boolean(this.state.scrollWrapperService);
        const menuStateDifferent = (prevProps.menuExpand !== this.props.menuExpand) || (prevProps.filtersOn !== this.props.filtersOn);
        if (serviceInitialized && menuStateDifferent) {
            setTimeout(() => {
                const scrollWrapperHeight = this.state.scrollWrapperService.calculateScrollWrapperHeight();

                this.setState({ scrollWrapperHeight });
            }, 500);
        }
    }

    componentWillUnmount() {
        const {
            clearAllTwoByTwoData,
            clearAllTrendsData,
        } = this.props;

        clearAllTwoByTwoData();
        clearAllTrendsData();
        window.removeEventListener('resize', this.onResize);
    }

    onChangeTwoByTwoOption = (data) => {
        this.getListData(data);
    }

    getProfileGlobalBrandEngagement = () => {
        /*
            if currentVariableMainFilter is one of ["General Population", "Aware of Brand", "Familiar with Brand"]
            and currentVariableMainGroup is one of ["Personification", "Customer Touchpoint"]
            ALWAYS should be used "Familiar with Brand" as currentVariableMainFilter
         */
        const {
            mainVariableFilterOptions,
            currentVariableMainFilter,
            currentVariableGroup,
            currentVariableFilter,
        } = this.props;
        const {
            option: { id: optionId },
        } = currentVariableFilter;
        const targetMainFilters = [0, 1, 2]; // ["General Population", "Aware of Brand", "Familiar with Brand"]
        const targetGroups = [3, 4]; // "Personification", "Customer Touchpoint"]

        if (targetMainFilters.includes(currentVariableMainFilter) && targetGroups.includes(currentVariableGroup)) {
            return mainVariableFilterOptions[2 /* "Familiar with Brand" */].filters[0];
        }

        /*
            additional change was requested.
            General Population > Conversion Funnel > Recommendation
            should have the same base ("Familiar with Brand") as above
        */
        const generalPopulationId = 0;
        const awareOfBrandId = 1;
        const conversionFunnelId = 2;
        const recommendationId = 2;
        const isRecommendation = [generalPopulationId, awareOfBrandId].includes(currentVariableMainFilter)
            && currentVariableGroup === conversionFunnelId
            && optionId === recommendationId;

        if (isRecommendation) {
            return mainVariableFilterOptions[2 /* "Familiar with Brand" */].filters[0];
        }
        return mainVariableFilterOptions[currentVariableMainFilter]?.filters[0];
    };

    initializeAndUseScrollWrapperService = () => {
        const scrollWrapperService = new EquityScrollWrapperHeightService(selectors);
        const scrollWrapperHeight = scrollWrapperService.calculateScrollWrapperHeight();

        this.setState({
            scrollWrapperService,
            scrollWrapperHeight,
        });
    };

    onResize = () => {
        setTimeout(() => {
            const scrollWrapperHeight = this.state.scrollWrapperService.calculateScrollWrapperHeight();

            this.setState({ scrollWrapperHeight });
        }, 500);
    };

    emulateLoading = (loading, readyShow) => this.setState({
        loading,
        readyShow,
    });

     // Implementation of Draggable and Reordering of the Brands with React-beautiful-dnd 
     reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
      };

    changeBrandsOrder = async(userId,checkData,selectedBrandsIndex)=>{
        await ApiData.updateBrandsOrderOrColour(userId,this.props.selectedCampaign.marketId,checkData);
        await this.props.onBrandsChange(selectedBrandsIndex);
    }

    onDragEnd = async(result) => {
        let {selectedCampaign, brands} = this.props;
        try{
            if (!result.destination) {
                return;
            }
            clearTimeout(this.debounceTimer);
            const items = this.reorder(
                this.state.brandData,
                result.source.index,
                result.destination.index
            );
            const data = await items.map((b, i) => {
                b.brand.position=i+1
                return b;
            });
            let selectedURLCIBrands = safeParseJSONSessionStorage('userSelectedBrands')?.find(({marketId})=> marketId == selectedCampaign?.marketId)?.brands;
            let otherbrands = brands?.filter(({id})=> !selectedURLCIBrands?.includes(id));
            otherbrands = [...otherbrands]?.reverse();
            let updatedBrandsOrderList = [...items?.map(b => b.brand),...otherbrands]
            updatedBrandsOrderList = updatedBrandsOrderList?.map((b,i)=> {
                b.position=i+1
                return b;
            });   
            await this.setState({ brandData:data},()=> setLocalStorage('myBrands', JSON.stringify(updatedBrandsOrderList)));
            const myUserInfo = getLocalStorage('userInfo');
            const userId = myUserInfo?JSON.parse(myUserInfo).id:'USER';
            let checkData = [];
            await updatedBrandsOrderList.forEach(item => { 
                    checkData.push({ entityId: item.id,
                    position: item.position,
                    })
            })
            let {selectedBrandsIndex} = getCompetitiveSelectedMarketAndBrands(selectedCampaign?.identity, selectedURLCIBrands);
            this.debounceTimer = setTimeout(() => this.changeBrandsOrder(userId,checkData,selectedBrandsIndex),1500);
        }
        catch(error) {
            if(error.message == "Network Error")this.setState({sessionTimeoutErr:true});
            console.log('Updating  error', error);
        };
    }

    getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: this.state.grid * 2,
        margin: `0 ${this.state.grid}px 0 0`,
        background: isDragging ? "lightgreen" : "grey",
      
        ...draggableStyle
    });

    LightenDarkenColor(col, amt) {
        var usePound = false;
        if(col){
            if (col[0] == "#") {
                col = col.slice(1);
                usePound = true;
            }
        }
        var num = parseInt(col,16);
        var r = (num >> 16) + amt;
        if (r > 255) r = 255;
        else if  (r < 0) r = 0;
        var b = ((num >> 8) & 0x00FF) + amt;
        if (b > 255) b = 255;
        else if  (b < 0) b = 0;
        var g = (num & 0x0000FF) + amt;
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    }

    getColors=(color) =>{
        if(color){
            const newColors = color.reduce((acc,next)=>{
                const colorCode= this.LightenDarkenColor(next, 80)
                let newAcc=[...acc];
                newAcc.push(next, colorCode)
                return newAcc;
            },[])
            const colors = newColors
            return colors;
        }
    }
      getListStyle = (isDraggingOver, itemsLength) => ({
        background: (isDraggingOver && this.props.selectedTab != 5) ? "white" : '',
        flexGrow: 1,
        marginRight: isDraggingOver ? "200px" : "0px"
    });
     
    render() {
        const {
            brandData,
            loading,
            readyShow,
            variableData,
        } = this.state;
        const {
            selectedTab,
            horizontalMenu,
            selectedPeriod,
            enddate,
            startdate,
            lineOption,
            onChangeLineOption,
            brands,
            onSelectVariableMainFilter,
            onSelectVariableFilter,
            onSelectVariableGroup,
            currentVariableFilter,
            currentVariableGroup,
            currentVariableMainFilter,
            variableGroupOptions,
            mainVariableFilterOptions,
            variableFilterOptions,
            variableOptionOpen,
            setVariableOptionOpen,
            main2x2FilterOptions,
            current2x2MainFilter,
            option2x2Open,
            set2x2OptionOpen,
            onSelect2x2MainFilter,
            axisSelectOptionChange,
            xAxisSelect,
            yAxisSelect,
            zAxisSelect,
            statTestingEnabled,
            page,
            useExposed,
            selectedCampaign,
            hiddenList,
            downloadexport
        } = this.props;
        const profileTabIndex = 5;
        let chartContent = [];
        const defaultSegment = initData.getDefaultSegment();
        let selectedURLCIBrands = safeParseJSONSessionStorage('userSelectedBrands')?.find(({marketId})=> marketId == selectedCampaign?.marketId)?.brands;
        let selectedBrands = brands?.filter(ele => selectedURLCIBrands?.some(id => id == ele?.id));
        selectedBrands = selectedBrands?.length>0 ? selectedBrands:brands;
        const rowsData = [defaultSegment, ...selectedBrands];
        if(selectedCampaign?.countryCode === 'HSS'){
            rowsData.forEach(item =>{ if(item.name === "Age 18+") item.name = "Age 35+"})
            variableData.forEach(item =>{ if(item.inputName === "Age 18+") item.inputName = "Age 35+"})
          }
        if (horizontalMenu === 'secondary' && (selectedTab !== 4 && selectedTab !== 5)) {
            chartContent.push(
                <BarChart
                    key='barChart'
                    selectedTab={selectedTab}
                    data={brandData.filter(b => { return b.tab===selectedTab })}
                    statTestingEnabled={statTestingEnabled}
                    page={page}
                    cperiod={selectedPeriod > 1 ? initData.getPeriodOptions()?.find(({id})=> id === selectedPeriod)?.name : startdate + ' to ' + enddate}
                    selectedMarket={selectedCampaign.marketId}
                    hiddenList={hiddenList}
                    getList={this.props.getBrandsByCampaign}
                    emulateLoading={this.emulateLoading}
                />
            );
        } else {
            switch (selectedTab) {
                default:
                    case 0:
                       
                        let equityData=brandData.filter(b=>{return b.tab===0}).sort((x,y)=>{return y.data.number - x.data.number});
                        equityData.length>0 && equityData.forEach( (bData, index)=> {
                            chartContent.push(
                            <Draggable key={bData.brand.id} draggableId={`${bData.brand.id}`} index={index}>
                                {(provided, snapshot) => (
                                  <div className='drag'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                 <EquityCard
                                  key={"eq" + index}
                                  tag={"eq" + index}
                                  id={index}
                                  brand={bData.brand.displayName ? bData.brand.displayName : bData.brand.name}
                                  logo={bData.brand.logo}
                                  data={bData.data.data}
                                  colors={initData.getDonutColors()}
                                  chartTitle={'EQUITY'}
                                  pool={bData.data.pool}
                                  number={bData.data.value}
                                  upDown={bData.data.upDown}
                                  upDownNA={bData.data.upDownNA}
                                  cperiod={selectedPeriod > 1 ? initData.getPeriodOptions()?.find(({id})=> id === selectedPeriod)?.name : startdate + ' to ' + enddate}
                                  statTestingEnabled={statTestingEnabled}
                                  higherThan={bData.higherThan}
                                  lowerThan={bData.lowerThan}
                                  page={page}
                                  selectedTab={selectedTab}
                                  selectedMarket={selectedCampaign?.countryCode}
                            />
                            {provided.placeholder}
                             </div>
                          )}
                        </Draggable>
                             );
                        });
                        break;
                    case 1:
                        brandData.filter(b=>{return b.tab===1}).forEach( (bData, index)=> {
                            let data=[];
                            data.push(bData.brand.color);
                            chartContent.push(
                                <Draggable key={bData.brand.id} draggableId={`${bData.brand.id}`} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                            <FunnelChartCard
                                key={'cv'+index}
                                tag={'cv'+index}
                                id={index}
                                data={bData.data.data}
                                colors = {this.getColors(data)}
                                brand={bData.brand.displayName ? bData.brand.displayName : bData.brand.name}
                                logo={bData.brand.logo}
                                brandValue={bData.data.pool}
                                hightRatio={0.8}
                                lowerThan={bData.lowerThan}
                                higherThan={bData.higherThan}
                                statTestingEnabled={statTestingEnabled}
                                selectedMarket={selectedCampaign?.countryCode}
                            />
                             </div>
                          )}
                        </Draggable>
                             );
                        });
                        break;
                    case 2:
                        brandData.filter(b=>{return b?.tab===2}).forEach( (bData, index)=> {
                            let data=[];
                            data.push(bData.brand.color);
                            chartContent.push(
                                <Draggable key={bData.brand.id} draggableId={`${bData.brand.id}`} index={index} style={{transition:'transform 0.2s;'}}>
                                    {(provided) => (
                                        <div  style={{transition:'transform 0.2s;'}}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <EmotionalCard
                                                key={'em' + index}
                                                label="emotional"
                                                brand={bData.brand.displayName ? bData.brand.displayName : bData.brand.name}
                                                logo={bData.brand.logo}
                                                brandValue={bData.data.pool}
                                                data={bData.data.data}
                                                colors={this.getColors(data)}
                                                lowerThan={bData.lowerThan}
                                                higherThan={bData.higherThan}
                                                statTestingEnabled={statTestingEnabled}
                                            />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Draggable>
                            );
                        });
                        break;
                case 3:
                    brandData.filter(b=>{return b.tab===3}).forEach( (bData, index)=> {
                        let data=[];
                        data.push(bData.brand.color);
                        chartContent.push(
                            <Draggable key={bData.brand.id} draggableId={`${bData.brand.id}`} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <AdvertisingCard
                                            key={"eq" + index}
                                            tag={"eq" + index}
                                            id={index}
                                            label="advertising"
                                            brand={bData.brand.displayName ? bData.brand.displayName : bData.brand.name}
                                            logo={bData.brand.logo}
                                            colors={this.getColors(data)}
                                            data={bData.data.data}
                                            chartTitle={'AD RECALL'}
                                            pool={bData.data.pool}
                                            upDown={bData.data.upDown}
                                            upDownNA={bData.data.upDownNA}
                                            number={bData?.data?.data?.filter(item => item.label === "advertisingRecall")[0]?.value}
                                            cperiod={selectedPeriod > 1 ? initData.getPeriodOptions()?.find(({id})=> id === selectedPeriod)?.name : startdate + ' to ' + enddate}
                                            statTestingEnabled={statTestingEnabled}
                                            higherThan={bData.higherThan}
                                            lowerThan={bData.lowerThan}
                                            page={page}
                                            selectedTab={selectedTab}
                                            useExposed={useExposed}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        );

                    });
                    break;
                case 4:
                    chartContent.push(
                        <LineChart
                            getTendsOptionsData={this.getTendsOptionsData}
                            interval={this.props.interval}
                            key="lineChart"
                            stab = {lineOption.stab}
                            selectedOption = {lineOption.selectedOption}
                            selectedScoreOption = {lineOption.selectedScoreOption}
                            onChangeLineOption={typeof onChangeLineOption ==='function'?onChangeLineOption:null}
                            statTestingEnabled={statTestingEnabled}
                            page={page}
                            emulateLoading={this.emulateLoading}
                            axisSelectOptionChange={axisSelectOptionChange}
                            onChangeFunction={this.props.onChangePeriodFunction}
                            startdate={this.props.selectedDate.startdate}
                            enddate={this.props.selectedDate.enddate}
                            selectedPeriod={this.props.selectedDate.selectedPeriod}
                            dateFilterId = {this.props.dateFilterId}
                            yAxisSelect={yAxisSelect}
                            selectedTab={selectedTab}
                            selectedMarket={selectedCampaign}
                            downloadexport={downloadexport}
                            getList={this.props.onBrandsChange}
                        />
                    );
                    break;

                case 5:
                    chartContent.push(
                        <StackedBarChart
                            key={'StackedBarChart'}
                            chartType={'competitive'}
                            data={variableData}
                            rowsData={rowsData}
                            onSelectVariableMainFilter={onSelectVariableMainFilter}
                            onSelectVariableFilter={onSelectVariableFilter}
                            onSelectVariableGroup={onSelectVariableGroup}
                            currentVariableFilter={currentVariableFilter}
                            currentVariableGroup={currentVariableGroup}
                            currentVariableMainFilter={currentVariableMainFilter}
                            // variableSubfilterOptions={variableSubfilterOptions}
                            mainVariableFilterOptions={mainVariableFilterOptions}
                            variableGroupOptions={variableGroupOptions}
                            variableFilterOptions={variableFilterOptions}
                            variableOptionOpen={variableOptionOpen}
                            setVariableOptionOpen={setVariableOptionOpen}
                            statTestingEnabled={statTestingEnabled}
                        />
                    );
                    break;
                case 6:
                    chartContent.push(
                        <TwoByTwoChart
                            key={'TwoByTwoChart'}
                            main2x2FilterOptions={main2x2FilterOptions}
                            current2x2MainFilter={current2x2MainFilter}
                            option2x2Open={option2x2Open}
                            set2x2OptionOpen={set2x2OptionOpen}
                            onSelect2x2MainFilter={onSelect2x2MainFilter}
                            isDetailed={false}
                            page={page}
                            onChangeTwoByTwoOption={this.onChangeTwoByTwoOption}
                            axisSelectOptionChange={axisSelectOptionChange}
                            xAxisSelect={xAxisSelect}
                            yAxisSelect={yAxisSelect}
                            zAxisSelect={zAxisSelect}
                        />
                    );
                    break;
            }
        }

        return (
            <div ref={this.wrapperRef} style={{overflowX:(this.props.selectedTab == 5 || (horizontalMenu === 'secondary' && selectedTab !== 4))?"inherit":"auto",display:"flex",backgroundColor: '#F5F7FA'}}>            
                {/* {sessionTimeoutErr && <SessionTimeoutModal />} */}
                <DragDropContext onDragEnd={this.onDragEnd}  style={{transition:'transform 0.2s;'}}>
                    <Droppable droppableId="droppable" direction="horizontal" style={{transition:'transform 0.2s;'}} >
                    {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={this.getListStyle(snapshot.isDraggingOver, this.state.brandData.length)}
                        {...provided.droppableProps}
                    >
                        <div
                            className={cx({
                                scrollWrapper: selectedTab !== profileTabIndex,
                            })}
                            style={ selectedTab !== profileTabIndex
                                ? { height: this.state.scrollWrapperHeight }
                                : {}
                            }
                        >
                            <div
                                className={cx(
                                    "dataItemsContainer",
                                    {
                                        "loading": loading,
                                        "scrollBarHidden": selectedTab === 4 || horizontalMenu === 'secondary'
                                    })
                                }
                            >

                                {loading && <LoadingLayer />}
                                {readyShow && chartContent}
                            </div>
                            {provided.placeholder}
                        </div>
                    </div>
                    )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        setTwoByTwoBrands,
        setTrendsData,
        clearAllTrendsData,
        setTwoByTwoChartData,
        setTrendsData,
        clearAllTwoByTwoData,
        clearAllTrendsData,
    }, dispatch)
});

export default connect(
    null,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(CompetitiveIntelligenceHomeComponent);