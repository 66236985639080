import React, { Component } from 'react';
import cx from "classnames";
import ApiData from '../../utils/Api';
import initData from '../../utils/initData';
import Header from './Header';
import Menu from './Menu'; 
import Tabs from './Tabs';
import HorizontalBar from './HorizontalBar';
import MiniBar from '../widgets/Shared/MiniBar';
import FilterOptionComponent from '../widgets/FilterOptionComponent';
import UsersManagementComponent from '../UserAdmin/UsersManagement/UsersManagementComponent';
import AddUserComponent from '../UserAdmin/UsersManagement/AddUserComponent';
import UserProfile from '../UserAdmin/UsersManagement/UserProfile';
import GroupProfile from '../UserAdmin/GroupManagement/GroupProfile';
import ContractProfile from '../UserAdmin/GroupManagement/ContractProfile';
import BrandProfile from '../UserAdmin/BrandsManagement/BrandProfile';
import AddGroupComponent from '../UserAdmin/GroupManagement/AddGroupComponent';
import CreateContractComponent from '../UserAdmin/GroupManagement/CreateContractComponent';
import AddBrandComponent from '../UserAdmin/BrandsManagement/AddBrandComponent';
import BrandsManagementComponent from '../UserAdmin/BrandsManagement/BrandsManagementComponent';
import BrandSummaryHomeComponent from '../BrandSummaryComponent/BrandSummaryHomeComponent';
import CompetitiveIntelligenceHomeComponent from '../CompetitiveIntelligence/CompetitiveIntelligenceHomeComponent';
import DetailedIntelligenceHomeComponent from '../DetailedIntelligence/DetailedIntelligenceHomeComponent';
import AdvancedIntelligenceComponent from '../AdvancedIntelligence/AdvancedIntelligenceComponent';
import GlobalDashboardHomeComponent from '../GlobalDashboardComponent/GlobalDashboardHomeComponent';
import ProductSuiteComponent from '../ProductSuite/ProductSuite';
import { getLocalStorage, getSessionStorage, setLocalStorage, setSessionStorage } from '../../utils/storageHelpers';
import { addParamsToUrl, excludePagesFromGetParamUpdateURLListData, getAudienceType, getInterval, getPeriodType, getSelectedDropDownOption, getStartEndDates, getURLPathParams, isInvalidValue, updateURLParams } from '../../utils/URLParamsValidations';
import './ContainerPage.scss'
import StatusMessage from '../widgets/Shared/StatusMessage';
import filtersTransformations from "../../utils/filterTransformations";
import CampaignManagementComponent from "../UserAdmin/GeographyManagement/CampaignManagementComponent";
import CampaignProfile from "../UserAdmin/GeographyManagement/CampaignProfile";
import LoadingLayer from '../widgets/Shared/LoadingLayer';
import compassLogoPng from '../../assets/icons/harris-logo.png';
import AddCampaignComponent from '../UserAdmin/GeographyManagement/AddCampaignComponent';
import AddDecipherSurveyComponent from '../UserAdmin/GeographyManagement/AddDecipherSurveyComponent';
import { connect } from 'react-redux';
import  { ADMINSEARCH_TERM } from '../../reducers/AdminSearch/SearchReducer';
import GroupManagementComponent from '../UserAdmin/GroupManagement/GroupManagementComponent';
import {setCustomSegments} from '../../reducers/TwoByTwoChart/actionCreators';
import * as d3 from "d3";
import { DateTime } from 'luxon';
import WarningModal from '../widgets/Shared/WarningModal';
import { safeParseJSONLocalStorage, safeParseJSONSessionStorage } from '../../utils/getBrowserStorageData';
import { setUserSelectedMarketsByBrand, setUserSelectedBrandsByMarket, setUserSelectedMarkets, getSelectedGlobalBrandMarketsIds } from '../../utils/common';
const pendo = window.pendo;

 class ContainerPage extends Component {
    constructor(props) {
        super(props);

        this.wrapperRef = null;
        const myUserInfo = getLocalStorage('userInfo');
        this.userType = JSON.parse(myUserInfo).userType;
        this.authorizedType = getLocalStorage('authorizedType');
        this.pendoParse = JSON.parse(myUserInfo);
        if(pendo !== undefined){
        pendo.initialize({
                visitor: {
                    id:  this.pendoParse.id,   
                    email: this.pendoParse.email, 
                    company : this.pendoParse.company,
                    position : this.pendoParse.position, 
                },
                account: {
                    id:  this.pendoParse.company,
                    name: this.pendoParse.company
                },
                location: {
                    setUrl: `${process?.env?.REACT_APP_AUTH0_COMPLETE_URL}${window?.location.pathname}` // or static string or URL 
                }
            });
        }
        let brands = [];
        let markets = [];
        let parsedGlobalBrands = [];
        let tabPages = ['segments', 'competitive', 'global', 'advanced']
        if (this.authorizedType === 'USER') {
            const myBrands = getLocalStorage('myBrands');
            const localCampaigns = getLocalStorage('markets');
            const globalBrands = getLocalStorage('globalBrands');
            setUserSelectedMarketsByBrand();
            if (myBrands?.length === 0 || myBrands === null) {
                this.props.history.push('/selectbrand');
            } else {
                brands = JSON.parse(myBrands);
                markets = JSON.parse(localCampaigns);
                parsedGlobalBrands = JSON.parse(globalBrands);
            }
        }
        let selectedTab = (this.props.history.location.state && this.props.history.location.state.tab) ? this.props.history.location.state.tab : 0;
        const { page } = this.props;
        const tabList = initData.getTabs(page).map((data)=> data.name.toLowerCase());
        if(tabPages.indexOf(page) !== -1 && this.props.match.params?.tab) {
            let tabName = this.props.match.params.tab;
            tabName = tabName === 'prepost'? 'pre/post':tabName;
            selectedTab = tabList.indexOf(tabName);
        }
        const selectedCampaign = this.authorizedType ==='USER' && this.getSelectedCampaign();
        const {brandsIndex, globalBrandsIndex, selectedMarketsIndex, selectedBrandsIndex} = this.authorizedType ==='USER' && this.getURLBrandsMarkets(brands,parsedGlobalBrands);
        const {audienceType, statTestingEnabled, startdate, enddate, selectedPeriod, interval, pselectedPeriod, pstartdate, penddate, chartType, group, metric, response} = this.getDefaultURLParams(selectedTab);
        const defaultLineOption = {stab: group, selectedOption: metric,selectedScoreOption: response };
        let selectedURLbrands = getURLPathParams()?.brands;
        this.authorizedType ==='USER' && setUserSelectedBrandsByMarket(selectedCampaign?.marketId,selectedURLbrands);
        this.state = {
            menuExpand: JSON.parse(getSessionStorage('sideMenu')),
            filterOn: false,
            selectedTab,
            selectedBrand: this.authorizedType==='USER' ? brandsIndex : [],
            selectedCompetitiveBrands: this.authorizedType==='USER' ? selectedBrandsIndex : [],
            selectedPctBrand: this.authorizedType==='USER' ? selectedBrandsIndex : [],
            selectedGlobalBrand: this.authorizedType==='USER' ? globalBrandsIndex : [],
            selectedGlobalPctBrand: this.authorizedType==='USER' ? parsedGlobalBrands.map((b,i) => { return i }) : [],
            selectedGlobalMarkets: this.authorizedType==='USER' ? selectedMarketsIndex : [],
            menuOpened: false,
            horizontalMenu: chartType,
            selectedPeriod:  selectedPeriod,
            interval: interval,
            enddate: enddate,
            startdate: startdate,
            pselectedPeriod: pselectedPeriod,
            penddate: penddate,
            pstartdate: pstartdate,
            searchFilter: [],
            currentFilters: [],
            segments: [],
            brands: brands,
            globalBrands: parsedGlobalBrands,
            brandSsize: undefined,
            readyShow: true,
            brandHidden: [],
            segmentHidden: [],
            lineOption: defaultLineOption,
            prePostLineOption: defaultLineOption,
            adminPanelStatusMessage: {
                type: null,
                isActive: false,
                arg: '',
            },
            currentVariableMainFilter: 1,
            currentVariableGroup: 1,
            currentVariableFilter: {
                index: 1,
                option: {},
            },
            variableOptionOpen: '',
            variableGroupOptions: [],
            variableFilterOptions: [],
            mainVariableFilterOptions: [],
            current2x2MainFilter: 0,
            option2x2Open: '',
            ...this.getDiscoverAxis(),
            statTestingEnabled: statTestingEnabled,
            isDataAvailable: false,
            filterOpen: false,
            currentOption: audienceType,
            markets: markets,
            selectedCampaign: selectedCampaign,
            loading: false,
            previousOption:0,
            hiddenListInBarChart: [],
            pendoPathvalue:'',
            periodValue: true,
            intervalValue:false,
            selectModeInterval:true,
            summaryAPIRestrict:false,
            marketDataUpdated:[],
            downloadexport:false,
            filtersData: [],
            categoryList:[],
            postPeriodValue:false,
            expiredFilters:[],
            removedBrands:[],
            inValidORSharedSegments: []
        };
        this.debounceTimer = false;
        this.authorizedType==='USER' && this.getBrandsByCampaign(selectedCampaign);
        this.authorizedType==='USER' && page !== 'summary' && this.getSegments();
        this.onClickShowOrHide = this.onClickShowOrHide.bind(this);
        this.onChangeLineOption = this.onChangeLineOption.bind(this);
        this.onCampaignEdit = this.onCampaignEdit.bind(this);
    }

    getParamsUpdateState = async(selectedFilters, needToCallBrandsAPI = false) => {
        let {selectedGlobalBrand, selectedBrand} = this.state;
        let {page, setCustomSegments} = this.props;
        let stateValues = this.state;
        stateValues.page = page;
        stateValues.applyFilters = selectedFilters;
        stateValues.audienceTypeOptions = this.getModes()?.modeOptions;
        let { currentOption, statTestingEnabled, startdate, enddate, selectedPeriod, interval, pselectedPeriod, pstartdate, penddate, market, applyFilters, expiredFilters, selectedGlobalBrandIndex, horizontalMenu,selectedBrandIndex, 
        lineOption, currentVariableMainFilter, currentVariableGroup, currentVariableFilte, selectedCompetitiveBrands, removedBrands, selectedMarketIndexes, segments, segmentsList, inValidORSharedSegments } = await addParamsToUrl(stateValues, this.getFilters)
        applyFilters?.length> 0 && this.filtersSetting && this.filtersSetting.changeCurrentFilters(applyFilters,0);
        setSessionStorage('applyFilters',JSON.stringify(applyFilters));
        let cfilters = JSON.parse(getSessionStorage('applyFilters'));
        let formatFilterList = this.getAppliedFilters();
        if(selectedGlobalBrandIndex > -1){
            selectedGlobalBrand = selectedGlobalBrand?.filter(id=> id != selectedGlobalBrandIndex);
            selectedGlobalBrand.unshift(selectedGlobalBrandIndex);
        } 
        if(selectedBrandIndex > -1 && page !== 'advanced'){
            selectedBrand = selectedBrand?.filter(id=> id != selectedBrandIndex);
            selectedBrand.unshift(selectedBrandIndex);
        }
        segmentsList?.length> 0 && this.props.setCustomSegments(segmentsList);

        //Update state variables as per params
        this.setState({
            currentOption, 
            statTestingEnabled, 
            selectedPeriod,
            startdate, 
            enddate,
            interval,
            pselectedPeriod,
            pstartdate,
            penddate,
            applyFilters,
            selectedBrand,
            selectedGlobalBrand,
            removedBrands,
            expiredFilters,
            removedBrands,
            horizontalMenu,
            segments,
            selectedCompetitiveBrands,
            inValidORSharedSegments,
            selectedPctBrand:selectedCompetitiveBrands,
            selectedCampaign: market,
            currentFilters: cfilters,
            searchFilter: formatFilterList,
            selectedGlobalMarkets:selectedMarketIndexes
        });
        let pathName = window?.location?.pathname;
        if(pathName?.includes('trends')){
            this.setState({lineOption});
        }else if(pathName.includes('profile')){
            this.setState({
                currentVariableMainFilter,
                currentVariableGroup,
                currentVariableFilte,
            })
        }else if(pathName.includes('prepost')){
            this.setState({prePostLineOption:lineOption});
        }
        if(needToCallBrandsAPI || !excludePagesFromGetParamUpdateURLListData?.includes(this.props.page)) {
            this.setState({},()=>this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData))
        }
   }

    getDefaultURLParams = (selectedTab) => {
        let {page} = this.props;
        let {audienceType, statTest, periodType, periodStartDate, periodEndDate, intervalType, postPeriodType, postStartDate, postEndDate, chartType, group, metric, response} = getURLPathParams();
        let startdate = DateTime.now().minus({days: 180}).toFormat('yyyy-MM-dd');
        let enddate = DateTime.now().minus({days: 1}).toFormat('yyyy-MM-dd');
        let pstartdate = DateTime.now().minus({days: 180}).toFormat('yyyy-MM-dd');
        let penddate = DateTime.now().minus({days: 1}).toFormat('yyyy-MM-dd');
        let statTestingEnabled = JSON.parse(getLocalStorage('statTestingEnabled')) === null?true:JSON.parse(getLocalStorage('statTestingEnabled'));

        //valid all params if not found set default values
        statTest = !(isInvalidValue(statTest)) ? (statTest == 'true' || statTest == 'false') ? statTest : statTestingEnabled : statTestingEnabled;
        periodType = getPeriodType(periodType,  periodStartDate, periodEndDate, page, selectedTab);
        intervalType = getInterval(intervalType, 'TWELVEWEEKS');
        postPeriodType = getPeriodType(postPeriodType, postStartDate, postEndDate, page, selectedTab);
        let periodStatEndDates = getStartEndDates(periodType, periodStartDate, periodEndDate, startdate, enddate);
        let postPeriodTypeStarEndtDate = getStartEndDates(postPeriodType, postStartDate, postEndDate, pstartdate, penddate);
        periodStartDate = periodStatEndDates.startDate;
        periodEndDate = periodStatEndDates.endDate;
        postStartDate = postPeriodTypeStarEndtDate.startDate;
        postEndDate = postPeriodTypeStarEndtDate.endDate;
        chartType = Object.values(initData.getHorizontalMenuNames()).includes(chartType?.toLowerCase())?chartType:'primary';
        chartType = isInvalidValue(chartType)?'primary':chartType.toLowerCase();

        let trendsTabOptions = initData.gettrendsTabOptions();
        let trendsOptionCategories = initData.getTrendsOptionCategories();
        let trendsScoreDecompositionvalues = initData.getTrendsOptionCategories();

        let defaultselectedGroup = trendsTabOptions?.find(({id})=> id == 0);
        let groupName = getSelectedDropDownOption(trendsTabOptions, group, defaultselectedGroup)?.name;
        group = getSelectedDropDownOption(trendsTabOptions, group, defaultselectedGroup)?.id;
        
        let defaultselectedMetric = trendsOptionCategories[group]?.find(({id})=> id == 'total');
        metric = getSelectedDropDownOption(trendsOptionCategories[group], metric, defaultselectedMetric)?.id;
        
        trendsScoreDecompositionvalues = metric ==='consideration'?initData.getTrendsOptionCategories()[5]:initData.getTrendsOptionCategories()[4];
        let defaultselectedResponse = trendsScoreDecompositionvalues?.find(({id})=> id == 'positive');
        response = getSelectedDropDownOption(trendsScoreDecompositionvalues, response, defaultselectedResponse)?.id;
        audienceType = getAudienceType(audienceType, page, selectedTab, {group:groupName, metric});
        return { 
            audienceType: audienceType?.id,
            statTestingEnabled: JSON.parse(statTest),
            startdate: DateTime.fromFormat(periodStartDate, 'yyyyMMdd').toFormat('yyyy-MM-dd'),
            enddate: DateTime.fromFormat(periodEndDate, 'yyyyMMdd').toFormat('yyyy-MM-dd'),
            selectedPeriod: periodType?.id,
            interval: intervalType?.id,
            pselectedPeriod: postPeriodType?.id,
            pstartdate: DateTime.fromFormat(postStartDate, 'yyyyMMdd').toFormat('yyyy-MM-dd'),
            penddate: DateTime.fromFormat(postEndDate, 'yyyyMMdd').toFormat('yyyy-MM-dd'),
            chartType,
            group,
            metric,
            response
        }
    }

    getSelectedCampaign = () => {
        let market = getURLPathParams()?.selectedMarket;
        let markets = safeParseJSONLocalStorage('markets');
        let selectedMarket=[]
        markets?.length> 0 && markets.forEach(({marketGroupsList}) => {
            let findSelectedMarket = marketGroupsList.find(({identity}) => identity?.toUpperCase() == market?.toUpperCase());
            findSelectedMarket && selectedMarket.push(findSelectedMarket)
        });
        let defaultMarket = markets?.length>0 && markets[0]?.marketGroupsList[0];
        market = isInvalidValue(market)? defaultMarket : selectedMarket?.length>0 ? selectedMarket[0] : defaultMarket;
        return market;
    }

    getSelectedGlobalBrand = () => {
        let brand = getURLPathParams()?.selectedBrand;
        let globalBrands = safeParseJSONLocalStorage('globalBrands');
        let selectedGlobalBrand = globalBrands?.findIndex(({id})=>id == brand);
        selectedGlobalBrand = isInvalidValue(selectedGlobalBrand) ? 0:selectedGlobalBrand>-1?selectedGlobalBrand:0;

        return selectedGlobalBrand;
    }

    errorMessage = (err) =>{
        this.setState({error:err});
    }

    async componentDidMount() { 
        let {page} = this.props;      
        this.getUpdatedTime();
        let selectedMarket = this.getSelectedCampaign();
        let selectedGlobalBrand = this.getSelectedGlobalBrand();
        let selectedMarketOrGlobalBrand = page === 'global'?selectedGlobalBrand:selectedMarket?.marketId;
        if(this.authorizedType === 'USER') {
            await this.getFilters(page, selectedMarketOrGlobalBrand);
        }
        let filters =  JSON.parse(getLocalStorage('filters'));
        if (filters?.length && this.authorizedType === 'USER') {
            await this.initFilters(selectedMarketOrGlobalBrand);
            this.setDiscoverAxis();
        }
        await this.getParamsUpdateState();
    }

    async componentDidUpdate(prevProps) {
        const { location: { pathname }, page} = this.props;

        if(page !== prevProps.page && page !== 'summary' && this.authorizedType === 'USER'){
            !(this.state.segments?.length) && await this.getSegments();
        }
        
        const userSortingPathes = ['/users', '/user/'];
        if (!userSortingPathes.some((item) => pathname.includes(item))) {
            localStorage.removeItem('userSorting');
        }
        
        //check if path conatins tab name using below regular expression
        const pathPattern = /^\/([^/]+)\/([^/]+)$/;
        const match = pathPattern.exec(pathname);
        if(pathname === '/segments'|| pathname === '/competitive' || pathname === 'global'){
            if(match){
                this.props.history.push(`${pathname}`)
            }else{
                this.props.history.push(`${pathname}/equity`)
            }
        }
        if(this.authorizedType === 'USER') updateURLParams();
    }

    initFilters = async(selectedMarketOrBrand) => {
        let filters = JSON.parse(getLocalStorage('filters'));
        const brandEngagementFilter = filters?.find(category => category.categoryId === 8).filters?.find(filter => filter.name === 'ENGAGEMENT');
        const conversionRecommendationFilter = filters?.find(category => category.categoryId === 8).filters?.find(filter => filter.name === 'NPS (NET PROMOTER SCORE)');
        const awareOfBrand = [];
        const familiarWithBrand = [];
        const triedBrand = [];
        const useBrand = [];
        const recommendBrand = [];

        if(brandEngagementFilter) {
            awareOfBrand.push({
                filterOptions: [...brandEngagementFilter.filterOptions]
                    .filter(item => item.value === 'Familiar with' || item.value === 'Just know the name')
                    .reduce((acc, property) => acc.concat(property.groupingIds), []),
                filterId: brandEngagementFilter.id,
            });
            familiarWithBrand.push({
                filterOptions: [...brandEngagementFilter.filterOptions]
                    .filter(item => item.value === 'Familiar with')
                    .reduce((acc, property) => acc.concat(property.groupingIds), []),
                filterId: brandEngagementFilter.id,
            });
            triedBrand.push({
                filterOptions: [...brandEngagementFilter.filterOptions]
                    .filter(item => item.value === 'Regular user' || item.value === 'Occasional user' || item.value === 'Tried in the past')
                    .reduce((acc, property) => acc.concat(property.groupingIds), []),
                filterId: brandEngagementFilter.id,
            });
            useBrand.push({
                filterOptions: [...brandEngagementFilter.filterOptions]
                    .filter(item => item.value === 'Regular user' || item.value === 'Occasional user')
                    .reduce((acc, property) => acc.concat(property.groupingIds), []),
                filterId: brandEngagementFilter.id,
            });
        }
        if (conversionRecommendationFilter) {
            recommendBrand.push({
                filterOptions: [...conversionRecommendationFilter.filterOptions]
                    .filter(item => item.value === 'Promoter')
                    .reduce((acc, property) => acc.concat(property.groupingIds), []),
                filterId: conversionRecommendationFilter.id,
            });
        }

        const {globalBrandEngagementList ,filtersList} = await ApiData.getGeoMainVariableFilterOptions();
        let { globalBrands ,selectedCampaign, selectedGlobalBrand, currentVariableGroup } = this.state;
        let { currentVariableFilter } = this.state;
        let { page}  = this.props;
        let body;
        let {metric, response} = getURLPathParams();

        if(!isInvalidValue(metric) && window.location.pathname.includes('profile')){
            currentVariableGroup = metric;
            currentVariableGroup = filtersList.find(({name})=> name.replace(/ /g,"")?.toLowerCase() == currentVariableGroup?.toLowerCase())?.id;
            currentVariableGroup = isInvalidValue(currentVariableGroup)?filtersList[0]?.id:currentVariableGroup;
        }
        if(page === 'global'){
            selectedMarketOrBrand = selectedMarketOrBrand ? selectedMarketOrBrand : selectedGlobalBrand[0];
            const brand = globalBrands[selectedMarketOrBrand];
            let {geoIds , useHSSLabels} = getSelectedGlobalBrandMarketsIds(brand);
    
            body = { 
                geoIds,
                useHSSLabels 
            }
        }else{
            selectedMarketOrBrand = selectedMarketOrBrand?selectedMarketOrBrand:selectedCampaign.marketId;
            let {sortedMarketAry} = this.getSelectedGlobalBrandMarkets();
            let useHSSLabels = sortedMarketAry?.find(ele => ele?.marketId === selectedMarketOrBrand)?.countryCode === 'HSS'? true : false;
            body = { 
                geoIds:[selectedMarketOrBrand?selectedMarketOrBrand:selectedCampaign.marketId],
                useHSSLabels: useHSSLabels
            }
        }
        let {filterOptionsList} = await ApiData.getGeoSubVariableFilterOptions(currentVariableGroup, body);
        let mainVariableFilterOptions = globalBrandEngagementList;
        let variableGroupOptions = filtersList;
        let variableFilterOptions = filterOptionsList;
        let selectedvariableFilterOption;
        selectedvariableFilterOption = variableFilterOptions.find(({id})=> id == currentVariableFilter?.index);
        if(!isInvalidValue(metric) && window.location.pathname.includes('profile')){
            selectedvariableFilterOption = response;
            selectedvariableFilterOption = variableFilterOptions.find(({name})=> name.replace(/ /g,"")?.toLowerCase() == selectedvariableFilterOption?.toLowerCase());        
        }

        this.main2x2FilterOptions = [
            {
                id: 0,
                name: 'All Segments',
            },
            {
              id: 1,
              name: 'Custom Segments',
            },
        ];
        this.setState({ mainVariableFilterOptions, variableGroupOptions,variableFilterOptions,
            currentVariableFilter: {
                index: selectedvariableFilterOption?selectedvariableFilterOption?.id:variableFilterOptions[0]?.id,
                option: selectedvariableFilterOption?selectedvariableFilterOption:variableFilterOptions[0],
            }
         });
    }

    getUpdatedTime = () =>{
        let marketsList = [];
        let marketList  = JSON.parse(getLocalStorage('markets'))
        if(marketList){
            marketList.forEach(item=>{
                item.marketGroupsList.forEach(marketObj=>{
                    marketsList.push(marketObj.marketId)
                })
            })
            let payload={
                "geoIds": marketsList
            }
            ApiData.getUpdated(payload).then(
                (result) => {
                    const updatedTime = Object.entries(result);
                    this.setState({
                        marketDataUpdated : updatedTime
                    });
                }
            ).catch(
                (err) => {
                    console.log("error=" + err );
                }
            );
        }
    }

    getDiscoverAxis = () => {
        const subcategoriesList = initData.getSubcategoriesList()
        return {
            xAxisSelect: {
                category: 0,
                subcategory: subcategoriesList[0][0]
            },
            yAxisSelect: {
                category: 0,
                subcategory: subcategoriesList[0][0]
            },
            zAxisSelect: {
                category: 0,
                subcategory: subcategoriesList[0][0]
            },
        }
    }

    setDiscoverAxis = () => {
        this.setState({ ...this.getDiscoverAxis() })
    }

    set2x2OptionOpen = (filterType) => {
        const { option2x2Open } = this.state;
        this.setState({option2x2Open: option2x2Open === filterType ? '' : filterType });
    }

    downloadPngPdfExports = () => {
       this.setState({downloadexport: !this.state.downloadexport})
    }

    onSelect2x2MainFilter = (index) => {
        this.resetHiddenList('segmentHidden');
        this.setState({
            current2x2MainFilter: index,
            option2x2Open: '',
        }, this.pageEl.getListData);
    }

    axisSelectOptionChange = (axis) => (option) => {
        const subcategoriesList = initData.getSubcategoriesList()
        this.setState({
          [`${axis}AxisSelect`]: {
            category: option.category,
            subcategory: subcategoriesList[option.category].find(subcategory => subcategory.id === option.subcategory),
          }
        }, this.pageEl.getListData);
    }

    getFilters = async(page,selectedBrandORMarketId) => {
        let { selectedCampaign, selectedGlobalBrand } = this.state;
        let marketsList = [], payload;
        if(page === 'global'){
            selectedBrandORMarketId = selectedBrandORMarketId>-1 ? selectedBrandORMarketId : selectedGlobalBrand[0];
            let globalBrands = JSON.parse(getLocalStorage("globalBrands"))
            let marketList = globalBrands?.filter(val=> Number(val.id) === globalBrands[selectedBrandORMarketId]?.id)[0]?.marketGroups;
            if(marketList){
                marketList.forEach(item=>{
                    item.marketGroupsList.forEach(marketObj=>{
                        marketsList.push(marketObj.marketId)
                    })
                })
            }

            payload = {   
                geoIds: [...new Set(marketsList)] ,
                module: initData.getModuleType(page)
            }
        }else{
           selectedBrandORMarketId = selectedBrandORMarketId ? selectedBrandORMarketId : selectedCampaign.marketId;
           payload= {   
                geoIds: [selectedBrandORMarketId],
                module: initData.getModuleType(page === 'summary'?'segments':page)
            }
        }
        let filters = await ApiData.getFilters(payload);
        this.setState({
            filtersData:filters
        })
        await setLocalStorage('filters', JSON.stringify(filters));
    }

    resetHiddenList = (hiddenListName) => {
        this.setState({ [hiddenListName]: [] });
    }

    hiddenList = (listData) => {
        if(this.state.horizontalMenu === 'secondary'){
            this.setState({
                hiddenListInBarChart: listData
            })
        }else{
            this.setState({
                hiddenListInBarChart: []
            })
        }
    }

    toDataURL = url => ApiData.getExportImage(url)
        .then(response => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(response.data)
        }));
        
    async onMenuClick(index, pagename) {
        
        let dashboardPages =  ['segments', 'competitive', 'global', 'advanced'];
        let page = this.props.page;
        // Admin Search key set as Empty when click on admin dashboard menus using below props method
        this.props.searchUser('');
        let {currentOption, selectedGlobalBrand, selectedPeriod} = this.state;
        let selectedPage = pagename?.split('/')[1];
        if(this.authorizedType === 'USER' && selectedPage !== 'summary'){
            !(this.state.segments?.length) && await this.getSegments();
        }
        if(pagename === 'summary'){
            const selectedTab = initData.getMenu().competitive.tabs.filter((obj,ind)=> ind === index)[0]?.name;
            pagename = 'competitive/'+selectedTab.toLowerCase()
        }
        else if(dashboardPages.includes(pagename.replace('/',''))){
            const firstTab = initData.getTabs(pagename.replace('/',''))[index]?.name?.toLowerCase();
            pagename = pagename+`/${index === -1? '':firstTab.replace('/','')}`;
        }
        let {modeOptions, disabledModes} = this.getModes(selectedPage, index);
        let audienceTypeList = modeOptions?.filter(ele=> !disabledModes?.includes(ele.id));
        let audienceType = audienceTypeList?.filter(ele=> ele?.id === currentOption); 
        audienceType = audienceType?.length > 0 ? audienceType[0]:audienceTypeList[0];

        await updateURLParams({
            audienceType: audienceType?.name?.toUpperCase()?.replace(/ /g, ""), 
            module: selectedPage, 
            chartType: 'primary'
        },['segments','filters','option1' ,'option2' ,'option3','brands']);
        setSessionStorage('applyFilters',JSON.stringify([]));
        selectedPage === 'summary' && selectedPeriod === 2 && this.normalizeDateSelectors(index, selectedPage);
        this.authorizedType === "USER" && pagename.includes('profile') && this.initFilters(selectedGlobalBrand[0])
        this.setState({
            selectedTab: index,
            currentOption: audienceType.id,
            horizontalMenu: 'primary',
            filterOn: false,
            searchFilter:[],
            currentFilters:[], 
            periodValue:true
        },this.props.history.push({pathname: `${pagename}`, state: {tab: index}}));
        selectedPage !== 'summary' && this.authorizedType === "USER" && selectedPage !== page && await this.getFilters(selectedPage);
        let pendoPath = window?.location.pathname
        this.pendoLocation(index,pendoPath);   
        if(this.state.postPeriodValue  === true){
            this.setState({postPeriodValue:false})
        }
        await this.getParamsUpdateState();
    }

    onClickTabItem = async(tab) => {
        d3.selectAll('.tooltip').remove();
        this.props.searchUser('');
        let { page,location: { pathname }} = this.props;
        let {selectedPeriod, selectedMarket, selectedGlobalBrand} = this.state;
        if(page === 'advanced'){
            const selectedTab = initData.getMenu().advanced.tabs.filter((obj,ind)=> ind === tab)[0]?.name;
            pathname = `${selectedTab.replace('/','').toLowerCase()}`;
            this.props.history.push({ pathname, state: {tab: tab}})
        }
        const {selectedBrand ,currentOption ,lineOption , prePostLineOption, previousOption} = this.state;
        let sBrand=[],currentOpt;
        if (page === 'summary') {
            sBrand.push(tab);
            selectedBrand.forEach((s)=> {
                if (s!==tab) {
                    sBrand.push(s);
                }
            });
        } else {
            sBrand = selectedBrand;
        }
        let selectedOption = (page === 'segments' || page === "competitive" || page === "global")  && lineOption?.selectedOption;
        if((tab !== 3 && currentOption === 2)||tab === 4) {
             if(tab === 4 && selectedOption === 'recommend'){
                  currentOpt = 3;  
            }else if(tab === 4 && (selectedOption === 'familiarity'|| selectedOption === 'awareness')){
                currentOpt = 0; 
            }else {
                currentOpt = previousOption === 2 ? 0:previousOption;
            }
        }else if(page == 'advanced' && prePostLineOption.stab === 1 && tab === 3){
            currentOpt = 0; 
        }else{
            currentOpt = ((((page === 'segments' || page === 'competitive' || page === 'global' || (page === 'advanced')) && tab !== 3)) && previousOption === 2)?0:
            currentOption == 1?currentOption: previousOption;
        }
        let audienceType = initData.getModes().filter(obj=> obj.id === currentOpt)[0]?.name?.toUpperCase().replace(/ /g, "");
        updateURLParams({
            audienceType: audienceType,
            chartType: 'primary'
        });
        this.onBrandsChange();
        let pendoPath = window.location.pathname
        if(this.state.selectedTab !== tab ){
            this.pendoLocation(tab,pendoPath);
        }
        tab === 4 && selectedPeriod === 2 && this.normalizeDateSelectors(tab, page);
        let selectedMarketOrGlobalBrand = page === 'global'?selectedGlobalBrand[0]:selectedMarket?.marketId;
       if(tab === 5){this.initFilters(selectedMarketOrGlobalBrand);}
        await this.setState({
            selectedTab: tab,
            selectedBrand: sBrand,
            currentOption: currentOpt,
            horizontalMenu: 'primary'
        });
        if(excludePagesFromGetParamUpdateURLListData?.includes(this.props.page)) {
            this.getParamsUpdateState(undefined, true);
        } else {
            this.getParamsUpdateState();
        } 
    }

    menuToggle() {
        this.setState({
            menuOpened: !this.state.menuOpened,
            menuExpand: !this.state.menuOpened,
        })
    }

    onClickHorizontalMenuItem(name) {
        name === 'secondary' ? this.hiddenList(this.state.hiddenListInBarChart):this.hiddenList([])
        this.setState({
            horizontalMenu: name,
        });
        updateURLParams({chartType: name?.toUpperCase()});
    }

     onPostPeriodDate = (val) =>{
        this.setState({postPeriodValue:val})
    }
    
    async switchSite() {
        let pathValue ;
        let myBrands = JSON.parse(getLocalStorage('myBrands'));
        const localCampaigns = JSON.parse(getLocalStorage('markets'));
        let userID = JSON.parse(getLocalStorage('userInfo'))?.id;
        setSessionStorage('sideMenu', false);

        if(this.authorizedType === 'ADMIN'){
            try{
                let result = await ApiData.getUserInfo() 
                let localCampaignsCount = localCampaigns?.reduce((count, current) => count + current.marketGroupsList.length, 0);
                let resultmarketsCount = result?.marketGroups?.reduce((count, current) => count + current.marketGroupsList.length, 0);
                if( resultmarketsCount !== localCampaignsCount){
                    setLocalStorage('markets', JSON.stringify(result.marketGroups));
                }
                setUserSelectedMarketsByBrand();
                setLocalStorage('globalBrands', JSON.stringify(result.brands));
                let marketId = result.marketGroups?.length>0?result.marketGroups[0].marketGroupsList[0].marketId:0
                let brands = await ApiData.getUserBrandsByCampaign(userID,marketId)
                if (brands?.length !== myBrands?.length) {
                    setLocalStorage('myBrands', JSON.stringify(brands));
                    this.setState({brands:brands});
                }
                pathValue = brands?.length === 0? '/selectbrand':'/userdashbord'

            }catch(error) {
                console.log("error=" + error );
            };
        }
        const newAType = this.authorizedType==='ADMIN' ? 'USER' : 'ADMIN';
        const newURL = this.authorizedType==='ADMIN'? pathValue : '/admin';
        await setLocalStorage('authorizedType', newAType);
        pathValue = newURL === '/userdashbord' ? '/summary':newURL
        await setSessionStorage("redirectURL",JSON.stringify(pathValue));
        this.props.history.push(newURL);
        updateURLParams({},['segments']);
        await this.getParamsUpdateState();
    }

    onExpandClick() {
        const defaultSideMenuEnabled = JSON.parse(getSessionStorage('sideMenu'))
        setSessionStorage('sideMenu', !defaultSideMenuEnabled);
        this.setState({
            menuExpand: defaultSideMenuEnabled?false:!this.state.menuExpand,
            filterOn: !this.state.menuExpand? false : this.state.filterOn
        }, () => {
            setTimeout(() => {window.dispatchEvent(new Event('resize'));}, 500);
        })
    }

    filterOpen = () =>{
        setSessionStorage('sideMenu', false);
        this.setState({
            menuExpand: !this.state.filterOn ? false : this.state.menuExpand,
            filterOn: !this.state.filterOn
        }, () => {
            setTimeout(() => {window.dispatchEvent(new Event('resize'));}, 500);
        })
    }

    onClickFilter() {
        const {filterOn} = this.state;
        if (this.filtersEl) {
            this.filtersEl && this.filtersEl.createNewSegment();
        }
        if (!filterOn) {
            this.setState({
                menuExpand: false,
                filterOn: true
            })
        }
    }
   // Implemented call back for restricting multiple API calls in Summary page
    onPeriodChange = (value) =>{
        this.setState({periodValue:value})
    }
    onIntervalChange = (value) =>{
        this.setState({intervalValue:value})
    }
    onSelectModeChange = (value) =>{
        this.setState({selectModeInterval:value})
    }
    onMarketBrandChange = (value) =>{
        this.setState({summaryAPIRestrict:value})
    }

    onChangePeriod(selectedPeriod, startdate, enddate, dfilter, interval) {
        let searchFilter = this.state.searchFilter;
        this.setState({ selectedPeriod, startdate, enddate, searchFilter, interval}, this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData));
        let periodType = initData.getPeriodOptions()?.find(({id}) => id === selectedPeriod)?.key;
        let periodStartDate = DateTime.fromFormat(startdate, 'yyyy-MM-dd').toFormat('yyyyMMdd');
        let periodEndDate = DateTime.fromFormat(enddate, 'yyyy-MM-dd').toFormat('yyyyMMdd');
        let intervalType = initData.getIntervals(true)?.flatMap(opt => opt.options).find(({id})=>id === interval)?.key;
        intervalType = intervalType !== undefined ? intervalType : 'MV-12'
        let obj={
            periodType, 
            periodStartDate, 
            periodEndDate,
            intervalType
        }
        updateURLParams(obj);
    }
    
    onCampaignEdit(){
        if(this.state.selectedTab!==0){
            this.setState({selectedTab: 0})
        }
    }

    onChangePostPeriod(pselectedPeriod, pstartdate, penddate) {
        this.setState({ pselectedPeriod, pstartdate, penddate}, this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData));
        let postPeriodType = initData.getPeriodOptions(true)?.find(({id}) => id === pselectedPeriod)?.key;
        let postStartDate = DateTime.fromFormat(pstartdate, 'yyyy-MM-dd').toFormat('yyyyMMdd');
        let postEndDate = DateTime.fromFormat(penddate, 'yyyy-MM-dd').toFormat('yyyyMMdd');
        let obj={
            postPeriodType, 
            postStartDate, 
            postEndDate,
        }
        updateURLParams(obj);
    }

    async applySearch() {
        let formatFilterList = this.getAppliedFilters();
        let segments=[],filters=[];
        formatFilterList?.length > 0 && formatFilterList.forEach(({filterType,filterId,filterOptions})=>{
            if(filterType === 'SEGMENT') segments.push(filterId);
            else filters.push(...filterOptions);
        })
        await this.getParamsUpdateState(formatFilterList);
    }

    getAppliedFilters = () =>{
        let {page} = this.props;
        let {selectedCampaign , selectedGlobalBrand} = this.state;
        let cfilters = JSON.parse(getSessionStorage('applyFilters'));
        let selectedMarketOrBrand = page !== 'global'? selectedCampaign:selectedGlobalBrand[0];
        const formatFilterList = filtersTransformations.formatSelectedFilters(cfilters, selectedMarketOrBrand);
        return formatFilterList;
    }

    getSelectedGlobalBrandMarkets = (selectedGlobalBrandIndex, parsedGlobalBrands) =>{
        let brand = getURLPathParams()?.selectedBrand;
        let brandIndex = parsedGlobalBrands?.findIndex(({id})=> id == brand);
        brandIndex = brandIndex > -1 ? brandIndex: selectedGlobalBrandIndex;
        let globalBrands = parsedGlobalBrands ? parsedGlobalBrands : this.state.globalBrands;
        let selectedGlobalBrand = selectedGlobalBrandIndex > -1 ? brandIndex : this.state?.selectedGlobalBrand[0];
        const actualCampaigns = new Set();
        const sortedMarketAry = globalBrands[selectedGlobalBrand]?.marketGroups.reduce((acc, mg) => {
            mg.marketGroupsList.forEach(obj=> obj.countryCode = mg.groupName)
            acc.push(...mg.marketGroupsList);
            return acc;
        }, []).sort((a,b) => a.position - b.position)
        sortedMarketAry?.length > 0 && sortedMarketAry.forEach(market => actualCampaigns.add(market.marketId));
        const geoIds = Array.from(actualCampaigns);
        return {geoIds, sortedMarketAry};
    }

    getURLBrandsMarkets = (brands, globalBrands) => {
        let {page} = this.props;
        let params = getURLPathParams();
        let selectedBrand = params?.selectedBrand;
        let selectedBrands = params?.brands;
        let selectedMarketsList = params?.markets;

        let {sortedMarketAry} = this.authorizedType ==='USER' && this.getSelectedGlobalBrandMarkets(0, globalBrands);
        let brandsIndex = brands.map((b,i) => { return i });
        let globalBrandsIndex = globalBrands.map((b,i) => { return i });
        let selectedMarketsIndex = sortedMarketAry.map((b,i) => { return i });
        let selectedBrandsIndex = brands.map((b,i) => { return i });

        if(page === 'summary' || page === 'segments' || page === 'global'){
            if(brands?.length > 0){
                let selectedBrandIndex = isInvalidValue(selectedBrand)? 0:brands?.findIndex(obj=>Number(obj.id) == Number(selectedBrand));
                brandsIndex = brandsIndex.filter(id=> id != selectedBrandIndex);
                if(selectedBrandIndex>-1) brandsIndex.unshift(selectedBrandIndex);
            }
        } 
        if(page === 'competitive' || page === 'advanced'){
            if(selectedBrands?.length > 0 && brands?.length > 0){
                let selectedBrandsList = selectedBrands?.reduce((acc, id, index) => {
                    const brandId = brands.find((item) => item.id == id)?.id;
                        acc.push(brandId);
                    return acc;
                }, []);
    
                selectedBrands = selectedBrandsList?.length>0 ? selectedBrandsList:brands?.map(({id})=> id);
                selectedBrands = selectedBrands?.filter(id=>id);
                selectedBrandsIndex = selectedBrands?.reduce((acc, item, index) => {
                    const matchingIndex = brands.findIndex(obj => item == obj.id);
                    if (matchingIndex !== -1) {
                        acc.push(matchingIndex);
                    }
                    return acc;
                }, []);
                selectedBrandsIndex = selectedBrandsIndex?.length>0 ? selectedBrandsIndex:brandsIndex;
                brandsIndex = selectedBrandsIndex;
            }
        }else if (page === 'global'){
            if(selectedMarketsList?.length > 0 && globalBrands?.length > 0){
                let selectedGlobalBrandIndex = isInvalidValue(selectedBrand)?0:globalBrands?.findIndex(obj=>Number(obj.id) == Number(selectedBrand));
                globalBrandsIndex = globalBrandsIndex.filter(id=> id != selectedGlobalBrandIndex);
                if(selectedGlobalBrandIndex> -1) globalBrandsIndex.unshift(selectedGlobalBrandIndex);
                let selectedGlobalBrand = isInvalidValue(selectedBrand)? globalBrands[0]:globalBrands?.find(obj=>Number(obj.id) === Number(selectedBrand));
                let marketList = selectedGlobalBrand?.marketGroups?.reduce((acc, mg) => {
                    acc.push(...mg.marketGroupsList);
                    return acc;
                }, []).sort((a,b) => a.position - b.position).map(({identity})=>identity);
                let selectedMarkets = selectedMarketsList?.filter(ele=> ele);
                selectedMarkets = selectedMarkets?.length>0 ? selectedMarkets:marketList;
                selectedMarkets = selectedMarkets?.reduce((acc, id, index) => {
                    const marketIdentity = marketList?.find((item) => item == id);
                    acc.push(marketIdentity);
                    return acc;
                }, []);
                selectedMarketsIndex = marketList?.reduce((acc, identity, index) => {
                    const matchingIndex = selectedMarkets?.filter(identity=> identity)?.findIndex(item => item == identity);
                    if (matchingIndex !== -1) {
                        acc.push(index);
                    }
                    return acc;
                }, []);
                selectedMarketsIndex = selectedMarketsIndex?.length>0 ? selectedMarketsIndex:sortedMarketAry?.map((b,i) => { return i });
            }
        }
        return {brandsIndex, globalBrandsIndex, selectedMarketsIndex, selectedBrandsIndex};

    }

    getBrandsByCampaign = async(selectedMarket) => {
        let {selectedBrand} = this.state;
        const userID = JSON.parse(getLocalStorage('userInfo'))?.id;
        let markets = JSON.parse(getLocalStorage('markets'));
        if(markets){
            this.setState({loading: true});
            let market = selectedMarket?selectedMarket:markets[0]?.marketGroupsList[0];
            await ApiData.getUserBrandsByCampaign(userID,market.marketId).then(
                (result) => {
                    this.setState({loading: false});
                    if (!!result && result.length > 0) {
                        const bcolors = initData.getColors().map(color => color[0]);
                        result.forEach((obj,ind)=>{
                            if(!obj.color){
                                obj.color = bcolors[ind%10]
                            }
                        });
                        setLocalStorage('myBrands', JSON.stringify(result));
                        let brandsIndex = [];
                        let brands = [];
                        let selectedUserBrands = safeParseJSONSessionStorage('userSelectedBrands')?.find(({marketId})=>marketId == selectedMarket?.marketId)?.brands;
                        if(this.authorizedType === 'USER' & selectedUserBrands?.length > 0) { 
                            let selectedBrandsIndex = selectedUserBrands?.reduce((acc, item, index) => {
                                const matchingIndex = result.findIndex(obj => item == obj.id);
                                if (matchingIndex !== -1) acc.push(matchingIndex);
                                return acc;
                            }, []);
                            selectedBrandsIndex = selectedBrandsIndex?.length>0 ? selectedBrandsIndex:result?.map((b,i) => i);
                            brandsIndex =  selectedBrandsIndex;
                            brands = selectedUserBrands;
                        }else{
                            brandsIndex = result.map((b,i) => i);
                            brands = result.map((b) => b.id);
                        }
                        updateURLParams({brands: brands},['brands']);
                        setUserSelectedBrandsByMarket(selectedMarket?.marketId, brands);
                        this.setState({
                            selectedBrand: brandsIndex,
                            selectedCompetitiveBrands: brandsIndex,
                            selectedPctBrand: brandsIndex,
                            brands: result,
                            // selectedCampaign: market
                        })
                    }
                }
            ).catch(
                (error) => {
                    this.setState({loading: false});
                    console.log("error=" + error );
            });
        }
    }

    // Refreshing Brands after reorder 
    onBrandsChange = (selectedBrandsIndex) =>{
        const myBrands = getLocalStorage('myBrands');
        this.setState({
            brands:JSON.parse(myBrands),
            selectedCompetitiveBrands:selectedBrandsIndex?.length> 0 ?selectedBrandsIndex:this.state.selectedCompetitiveBrands,
            selectedPctBrand:selectedBrandsIndex
        });
    }

    onGeosChange = (selectedMarketIndexes) =>{
        const markets = getLocalStorage('markets');
        const globalBrands = getLocalStorage('globalBrands');
        this.setState({
            markets:JSON.parse(markets),
            globalBrands:JSON.parse(globalBrands),
            selectedGlobalMarkets:selectedMarketIndexes
        });
    }

    onSelectCampaign = async(campaign) => {
        const { selectedCampaign } = this.state;
        const { page } = this.props
        this.setState({
            summaryAPIRestrict:true, 
            selectModeInterval:false
        })
        if (selectedCampaign.marketId !== campaign.marketId) {
            this.setState({loading: true});
            await this.getFilters(page, campaign.marketId )
            if( window.location.pathname === "/competitive/profile" || window.location.pathname === "/segments/profile" ){ 
                await this.initFilters(campaign.marketId);
            }
            await this.getBrandsByCampaign(campaign);
            await updateURLParams({market: campaign?.identity},['segments']);
            let cfilters = JSON.parse(getSessionStorage('applyFilters'));
            const formatFilterList = filtersTransformations.formatSelectedFilters(cfilters, campaign);
            this.setState({searchFilter: formatFilterList});
            await this.getParamsUpdateState(formatFilterList);
        }
    }


    setSegmentsData = async(categoryList, deleteId) => {
        let {page} = this.props;
        let {selectedCampaign, selectedGlobalBrand} = this.state;
        let segmentsList = JSON.parse(getSessionStorage('segmentsList'));
        let cfilters = JSON.parse(getSessionStorage('applyFilters'));

        let filtersList = this.state.filtersData;
        if(Array.isArray(categoryList)){
            segmentsList = categoryList;
        }else if(Object.entries(categoryList)?.length){
            //removing the existing edit segment from previous category if category option changed, from segmentsList and filtersList
            segmentsList.forEach(({segments,categoryId},index)=>{
                if(categoryId !== categoryList.categoryId){
                    segments.forEach(({segmentId},ind)=>{
                        if(segmentId == categoryList.segments[0].segmentId){
                            segmentsList[index].segments.splice(ind,1);
                            segmentsList[index].segments?.length === 0 && (segmentsList.splice(index,1)); 
                        }
                    })
                }
            })
            filtersList.forEach(({filterResponse},index)=>filterResponse.filter(ele => !ele.categoryId).forEach(({filterId,filterGroup},ind)=>{
                if(filterId == categoryList.segments[0].segmentId && filterGroup !== categoryList.categoryName){
                    filtersList[index].filterResponse.splice(ind,1);
                }
            }))
            let newOrEditSegment = filtersTransformations.getFilterObject(categoryList); 
            let findCategory = segmentsList.findIndex(ele => ele.categoryId === categoryList.categoryId);
            let findCategoryInFilters = filtersList.findIndex(ele => !ele.categoryId);
            let findSegmentCategory = filtersList[findCategoryInFilters]?.filterResponse?.findIndex(ele => ele.filterId === categoryList.segments[0].segmentId);
            if(findCategoryInFilters < 0 && page !== 'segments'){
                filtersList.push({
                    categoryId: null,
                    categoryName: "Segments",   
                    categoryOrder: 0,
                    categoryType: "USER",
                    filterResponse: [newOrEditSegment]
                })
            }
            if(findCategory > -1){
                let findSegment = segmentsList[findCategory].segments.findIndex(ele=> ele.segmentId === categoryList.segments[0].segmentId);

                if(findSegment > -1){
                    segmentsList[findCategory].segments[findSegment] = categoryList.segments[0];
                    findCategoryInFilters > -1 && (filtersList[findCategoryInFilters].filterResponse[findSegmentCategory] = newOrEditSegment);
                }else{
                    segmentsList[findCategory].segments.push(...categoryList.segments);
                     findCategoryInFilters > -1 && filtersList[findCategoryInFilters].filterResponse.push(newOrEditSegment)
                }
            }else{
                segmentsList.push(categoryList);
                 findCategoryInFilters > -1 && filtersList[findCategoryInFilters].filterResponse.push(newOrEditSegment)
            }
        }else{
             segmentsList.forEach((categoryObj,ind)=>{
                if(categoryObj.categoryId !== deleteId){
                    let filteredSegments = categoryObj.segments.filter(ele=> ele.segmentId !== deleteId)
                    if(!(filteredSegments?.length)){
                       segmentsList.splice(ind,1);
                    }else{
                        categoryObj.segments = categoryObj.segments.filter(ele=> ele.segmentId !== deleteId)
                    }
                } 
            })
            filtersList && filtersList.forEach(categoryObj => {
                if(!categoryObj.categoryId){
                     categoryObj.filterResponse = categoryObj.filterResponse.filter(ele=> ele.filterId != deleteId);
                }   
            })         
        }
        let filteredSegments = await segmentsList.reduce((acc, sr) => {
                acc.push(...sr.segments);
                return acc;
            }, [])
        filteredSegments = await filteredSegments.filter(obj => obj?.isActive === true);
        await this.props.setCustomSegments(filteredSegments);
        updateURLParams({segments:filteredSegments.map(({segmentId})=>segmentId)},['segments'])
        await setSessionStorage('segmentsList',JSON.stringify(segmentsList))
        setLocalStorage('filters', JSON.stringify(filtersList));
        let selectedMarketOrBrand = page !== 'global'? selectedCampaign:selectedGlobalBrand[0];
        const formatFilterList = filtersTransformations.formatSelectedFilters(cfilters, selectedMarketOrBrand);
        await this.setState({
            segments: segmentsList,
            filtersData: filtersList,
            searchFilter: formatFilterList
        },this.pageEl.setState({}, this.pageEl.getListData))
    }

    updateSharedSegments = (segmentsList, filtersList) => {
        let cfilters = JSON.parse(getSessionStorage('applyFilters'));
        let formatFilterList = this.getAppliedFilters();
        cfilters?.length> 0 && this.filtersSetting && this.filtersSetting.changeCurrentFilters(cfilters,0);

        this.setState({
            segments: segmentsList,
            filtersData: filtersList,
            currentFilters: cfilters,
            searchFilter: formatFilterList
        });
        this.setState({},()=>this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData))
    }

    onSelectGlobalBrand = async(index, sindex) => {
        const {page} = this.props;
        const {selectedGlobalBrand, selectedGlobalPctBrand, selectedTab} = this.state;
        let sBrand= [];
        if(selectedGlobalBrand[0] !== index){
          await this.getFilters(page, index);
        }
        let selectedGlobalBrandId = safeParseJSONLocalStorage('globalBrands')[index]?.id;
        updateURLParams({brand: selectedGlobalBrandId});
        let cfilters = JSON.parse(getSessionStorage('applyFilters'));
        const formatFilterList = filtersTransformations.formatSelectedFilters(cfilters, index);
        this.setState({searchFilter: formatFilterList});
        if (page ==='advanced' && selectedTab === 1) {
            const checkIndex=selectedGlobalPctBrand.indexOf(index);
            if (checkIndex < 0) {
                sBrand.push(index);
            }

            selectedGlobalPctBrand.forEach((s, i)=> {
                if (s!==index) {
                    sBrand.push(s);
                }
            });

            this.setState({
                selectedGlobalPctBrand: sBrand
            });
        } else if(page === 'global'){
            let {sortedMarketAry} = this.getSelectedGlobalBrandMarkets(index);
            let selectedURLMarkets = safeParseJSONSessionStorage('userSelectedGlobalMarkets')?.find(({brandId})=> brandId == selectedGlobalBrandId)?.markets;
            sortedMarketAry = sortedMarketAry?.filter(ele => selectedURLMarkets?.some(id => id == ele.identity)).map(({identity})=> identity);
            setUserSelectedMarkets(selectedGlobalBrandId, sortedMarketAry);
            this.setState({
                selectedGlobalMarkets: sortedMarketAry?.map((b,i) => { return i })
            })
            updateURLParams({markets: sortedMarketAry},['markets']);
            await this.initFilters(index);
        } else {
            if (Number(sindex)===0) {
                sBrand[0] = index;
                selectedGlobalBrand.forEach((b) => {
                    if ( index !== b) {
                        sBrand.push(b);
                    }
                });
            } else {
                sBrand[0] = selectedGlobalBrand[0];
                if (page==='advanced' && selectedTab<2) {
                    const checkIndex=selectedGlobalBrand.indexOf(index);
                    if (checkIndex < 0) {
                        sBrand.push(index);
                    }

                } else {
                    sBrand[1] = index;
                }
                await selectedGlobalBrand.forEach((s, i)=> {
                    if (i > 0 && s!==index) {
                        sBrand.push(s);
                    }
                });
            } 
            await this.initFilters(sBrand[0]);
            this.setState({
                 selectedGlobalBrand: sBrand,
            });
        }
        await this.getParamsUpdateState(formatFilterList);
    }

    onSelectMarkets (index){
        clearTimeout(this.debounceTimer);
        let {selectedGlobalMarkets, selectedGlobalBrand} = this.state;
        let sMarket= [];
        const checkIndex = selectedGlobalMarkets.indexOf(index);
        if (checkIndex < 0) {
            sMarket.push(index);
        }

        selectedGlobalMarkets.forEach((s, i)=> {
            if (s!==index) {
                sMarket.push(s);
            }
        });
        let {sortedMarketAry} = this.getSelectedGlobalBrandMarkets(selectedGlobalBrand[0])
        let selectedMarkets = sMarket?.sort((a, b) => a - b)?.reduce((acc, id, index) => {
            const marketIdentity = sortedMarketAry.find((obj,ind) => ind == id)?.identity;
                acc.push(marketIdentity);
            return acc;
        }, []);
        let selectedGlobalBrandId = safeParseJSONLocalStorage('globalBrands')[selectedGlobalBrand[0]]?.id; 
        setUserSelectedMarkets(selectedGlobalBrandId ,selectedMarkets);
        this.initFilters(selectedGlobalBrand[0]);
        updateURLParams({markets: selectedMarkets},['markets']);
        this.setState({
            selectedGlobalMarkets: sMarket
        });
        this.debounceTimer = setTimeout(() => this.setState({},this.pageEl.setState({}, this.pageEl.getListData)),500);
    }

    pendoLocation(tab,pagename){
    const pathValue = process?.env?.REACT_APP_AUTH0_COMPLETE_URL+'/'+pagename
    if(pendo !== undefined){
        pendo.location.setUrl(pathValue.replace(/\/\//g, "/"));
     }
    }

    normalizeDateSelectors = (tab, page) => {
        let { selectedPeriod, interval, startdate, enddate, searchFilter } = this.state;
        let previousInterval =  getSessionStorage('previousInterval')

        // HBP-16 | Introduced Moving Average for Trends.
        // Moving Average is currently exclusively used for Trends as of Feb 2022.

        let showMovingAverage = ((page === 'competitive' || page === 'segments' || page === 'global') && tab === 4)|| page === 'summary';
        let intervalNext, intervalExclude = [];
        const range =  DateTime.fromFormat(enddate, 'yyyy-MM-dd').diff(DateTime.fromFormat(startdate, 'yyyy-MM-dd'),"days").days;
        if (range < 8) {
            if (!showMovingAverage) {
                intervalNext = 'DAYS';
                intervalExclude.push('WEEKS', 'MONTHS');
            }
            else {
                intervalNext = previousInterval === null? 'ONEWEEKS': previousInterval;
                intervalExclude.push('DAYS', 'WEEKS', 'MONTHS');
            }
        } else if (range > 7 && range < 31) {
            if (!showMovingAverage) {
                intervalNext = 'DAYS';
                intervalExclude.push('MONTHS');
            }
            else {
                intervalNext = previousInterval === null? 'WEEKS': previousInterval;
                intervalExclude.push('DAYS', 'MONTHS');
            }
        } else if (range > 30 && range < 182 && !showMovingAverage) {
            intervalNext = previousInterval === null? 'TWELVEWEEKS': previousInterval;
            intervalExclude.push('DAYS');
        } else if (range > 30 && range < 182 && showMovingAverage) {
            intervalNext = (previousInterval === null || previousInterval === undefined)? 'TWELVEWEEKS': previousInterval;
            intervalExclude.push('DAYS');
        } else {
            if(!showMovingAverage){
                intervalNext = 'MONTHS';
            }else if(showMovingAverage){
                intervalNext = previousInterval === null? 'TWELVEWEEKS': previousInterval;;
            }
            intervalExclude.push('DAYS');
        }

        const intervalOptions = initData.getIntervals(showMovingAverage, ...intervalExclude, true);
        const periodOptions = initData.getPeriodOptions(showMovingAverage);
        
        if (!intervalOptions.some(i => i.id === interval)) {
            interval = intervalNext;
        }

        if (!periodOptions.some(i => i.id === selectedPeriod)) {
            selectedPeriod = 6;
        }
        
        this.setState({
            selectedPeriod,
            interval,
            startdate,
            enddate,
            searchFilter
        });

        let periodType = initData.getPeriodOptions()?.find(({id}) => id === selectedPeriod)?.key;
        let periodStartDate = DateTime.fromFormat(startdate, 'yyyy-MM-dd').toFormat('yyyyMMdd');
        let periodEndDate = DateTime.fromFormat(enddate, 'yyyy-MM-dd').toFormat('yyyyMMdd');
        let intervalType = initData.getIntervals(true)?.flatMap(opt => opt.options).find(({id})=>id === interval)?.key;
        intervalType = intervalType !== undefined ? intervalType : 'MV-12'
        let obj={
            periodType, 
            periodStartDate, 
            periodEndDate,
            intervalType
        }
        updateURLParams(obj);
    }

    onSelectBrand(index, sindex) {        
        let brands = safeParseJSONLocalStorage('myBrands');
        let {page} = this.props;
        let {selectedBrand, selectedPctBrand, selectedCompetitiveBrands, selectedTab, selectedCampaign} = this.state;
        let sBrand= [];
        this.setState({summaryAPIRestrict:true})
        if(page === 'competitive' || (page === 'advanced' && selectedTab === 1)){
            clearTimeout(this.debounceTimer);
            const checkIndex = selectedCompetitiveBrands.indexOf(index);
            if (checkIndex < 0) {
                sBrand.push(index);
            }

            selectedCompetitiveBrands.forEach((s, i)=> {
                if (s!==index) {
                    sBrand.push(s);
                }
            });
            const selectedBrands = sBrand?.sort((a, b) => a - b)?.reduce((acc, id, index) => {
                const brandId = brands.find((item,ind) => ind == id)?.id;
                    acc.push(brandId);
                return acc;
            }, []);
            updateURLParams({brands: selectedBrands},['brands']);
            setUserSelectedBrandsByMarket(selectedCampaign?.marketId,selectedBrands);
            this.setState({
                selectedCompetitiveBrands: sBrand,
                selectedPctBrand: sBrand,
                selectedBrand: sBrand
            });
            if(page === 'competitive'){
                this.debounceTimer = setTimeout(() => this.setState({},this.pageEl.setState({}, this.pageEl.getListData)),500);
            }else{
                this.setState({},this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData))
            }
        }else {
            if (Number(sindex)===0) {
                sBrand[0] = index;
                selectedBrand.forEach((b) => {
                    if ( index !== b) {
                        sBrand.push(b);
                    }
                });
            } else {
                sBrand[0] = selectedBrand[0];
                if (page==='advanced' && selectedTab<2) {
                    const checkIndex=selectedBrand.indexOf(index);
                    if (checkIndex < 0) {
                        sBrand.push(index);
                    }

                } else {
                    sBrand[1] = index;
                }
                selectedBrand.forEach((s, i)=> {
                    if (i > 0 && s!==index) {
                        sBrand.push(s);
                    }
                });
            }
            let selectedBrands = sBrand?.reduce((acc, id, index) => {
                const brandId = brands?.find((item,ind) => ind == id)?.id;
                    acc.push(brandId);
                return acc;
            }, []);
            if(page === 'summary' || page === 'segments'){
                updateURLParams({brand: brands[index]?.id});
            }else{
                updateURLParams({brands: selectedBrands},['brands']);
            }
            setUserSelectedBrandsByMarket(selectedCampaign?.marketId,selectedBrands);
            this.setState({
                selectedBrand: sBrand
            },this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData));
        }
    }

    clearFiter(reset) {
        const { filterOn, menuExpand } = this.state;
        this.setState({
            searchFilter: [],
            currentFilters:[],
            menuExpand: !filterOn ? false : menuExpand,
            filterOn: false
        }, this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData));
        if (reset && this.filtersSetting) {
            updateURLParams({},['segments','filters']);
            this.filtersSetting.resetFilters(true);
        }
    }

    onClickSegment(segment) {
        this.setState({
            menuExpand: false,
            filterOn: true}, this.filtersEl.handleSegmentsActions('edit',segment?.segmentId));
    }

    getSegments = async() => {
        let segments = await ApiData.getUserSegments();
        let filteredSegments = segments.reduce((acc, sr) => {
            acc.push(...sr.segments);
            return acc;
        }, [])
        filteredSegments = filteredSegments.filter(obj => obj?.isActive === true)
        this.props.setCustomSegments(filteredSegments);
        await setSessionStorage('segmentsList',JSON.stringify(segments));
        await this.setState({segments});
    }

    onClickShowOrHide(index, hiddenListName, withoutRequest) {
        let hiddenList = this.state[hiddenListName];
        let position = (hiddenList.indexOf(index));
        if (position === -1) {
          hiddenList.push(index);
        } else {
          hiddenList.splice(position, 1);
        }

        if (withoutRequest) {
            this.setState({ [hiddenListName]:hiddenList });
        } else {
            this.setState({
                [hiddenListName]:hiddenList
            }, this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData));
        }
    }
 
    onChangeLineOption(option, optionName) {
        let {page} = this.props;
        let {selectedTab} = this.state;
        let currentOption = option !== 3 && this.state.currentOption === 2 ? 0:this.state.currentOption
        if(optionName === 'lineOption'){
            if((option.selectedOption === 'awareness'||option.selectedOption === 'familiarity')&& option.stab === 1){
                currentOption = 0
            }else if(option.selectedOption === 'recommend'){
                currentOption = 3   
            }else{
                currentOption = this.state.previousOption === 2?0:this.state.previousOption
            }
        }else if(page == 'advanced' && option.stab === 1 && selectedTab === 3){
            currentOption = 0
        }
        if(page === 'competitive'){
            const myBrands = getLocalStorage('myBrands');
            this.setState({brands:JSON.parse(myBrands)});
        }
        let audienceType = initData.getModes().filter(obj=> obj.id === currentOption)[0]?.name?.toUpperCase().replace(/ /g, "");
        let {group, metric, response} = this.getTrendsSelectedlabels(option);
        updateURLParams({"audienceType": audienceType, group, metric, response});
        this.setState({
            [optionName]:option,
            currentOption:currentOption
        }, this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData));
    }

    getTrendsSelectedlabels = (option) => {
        let {stab , selectedOption, selectedScoreOption} = option;
        let trendsTabOptions = initData.gettrendsTabOptions();
        let trendsOptionCategories = initData.getTrendsOptionCategories();
        let trendsScoreDecompositionvalues = initData.getTrendsOptionCategories();
        let group = trendsTabOptions?.find(({id})=> id == stab);
        let metric = trendsOptionCategories[group?.id]?.find(({id})=> id == selectedOption);
        trendsScoreDecompositionvalues = metric ==='consideration'?initData.getTrendsOptionCategories()[5]:initData.getTrendsOptionCategories()[4];
        let response = trendsScoreDecompositionvalues?.find(({id})=> id == selectedScoreOption);
        return {
            group: group?.name?.toUpperCase(), 
            metric: metric?.name?.toUpperCase()?.replace(/ /g,""), 
            response: response?.name?.toUpperCase()
        };
    }
    

    showAdminPanelMessage = (type, arg='') => {
        this.setState({
            adminPanelStatusMessage: { type, isActive: true, arg }
        });
    }

    hideAdminPanelMessage = () => {
        this.setState({
            adminPanelStatusMessage: { type: null, isActive: false, arg: ''}
        });
    }

    getStatusMessageText = () => {
        const { adminPanelStatusMessage } = this.state;
        if(adminPanelStatusMessage.isActive && adminPanelStatusMessage.type) {
            const messages = initData.getStatusMessages()[adminPanelStatusMessage.type[0]];
            return messages ? messages[adminPanelStatusMessage.type[1]] : '';
        } else return adminPanelStatusMessage.arg;;
    }

    onSelectVariableMainFilter = (index) => {
        let {mainVariableFilterOptions} = this.state;
        let group = mainVariableFilterOptions.find(({id})=> id == index)?.name?.replace(/ /g,"")?.toUpperCase();

        this.setState({
            currentVariableMainFilter: index,
            variableOptionOpen: '',
        },  () => {this.pageEl.setState({readyShow: true, loading: true}, this.pageEl.getListData)});
        updateURLParams({group});
    }

    onSelectVariableGroup = async(index) => {
        let {page} = this.props;
        let { variableFilterOptions, currentVariableGroup, variableGroupOptions, selectedCampaign, globalBrands, selectedGlobalBrand } = this.state;
        let option = {...variableFilterOptions[index]}, useHSSLabels, geoIds;
        if(page === 'global'){
            const selectedBrandObj = globalBrands[selectedGlobalBrand[0]];
            let result =  getSelectedGlobalBrandMarketsIds(selectedBrandObj)
            geoIds = result.geoIds;
            useHSSLabels = result.useHSSLabels;
        }else{
            useHSSLabels = selectedCampaign?.countryCode === 'HSS' ? true: false
        }
        geoIds = page === 'global' ? geoIds : [this.state.selectedCampaign.marketId];
        let body = { 
            geoIds: geoIds,
            useHSSLabels: useHSSLabels
        }

        if(currentVariableGroup !== index){
            try{
            let {filterOptionsList} = await ApiData.getGeoSubVariableFilterOptions(index, body);
            variableFilterOptions = filterOptionsList;
            option = {...variableFilterOptions[0]};
            }catch(err){
                option = {...variableFilterOptions[index]};
            }
        }
        let metric = variableGroupOptions.find(({id})=> id == index)?.name?.replace(/ /g,"")?.toUpperCase();
        let response = variableFilterOptions.find(({id})=> id == option?.id)?.name?.replace(/ /g,"")?.toUpperCase();
        updateURLParams({metric, response});
        this.setState({
            variableFilterOptions,
            currentVariableGroup: index,
            currentVariableFilter: {
                index: option.id,
                option,
            },
            variableOptionOpen: '',
        });
        this.setState({},()=>this.pageEl.setState({readyShow: true, loading: true}, this.pageEl.getListData));
    }

    onSelectVariableFilter = (index) => {
        let { variableFilterOptions, currentVariableMainFilter, currentVariableGroup, mainVariableFilterOptions } = this.state;
        const option = {...variableFilterOptions[index]};
        let response = variableFilterOptions.find(({id})=> id == index)?.name?.replace(/ /g,"")?.toUpperCase();
        let checkIsRecommendationFilter = index === 91 && currentVariableGroup === 3;
        let groupObj = mainVariableFilterOptions.find(({id})=>id === 5);
        currentVariableMainFilter = checkIsRecommendationFilter ? groupObj?.id: currentVariableMainFilter;
        let group = groupObj?.name?.toUpperCase()?.replace(/ /g,"")
        updateURLParams({group ,response});

        this.setState({
            currentVariableFilter: {index, option},
            variableOptionOpen: '',
            //if selected option is recommended(91) then defaultly set to usebrand filters
            currentVariableMainFilter: currentVariableMainFilter
        }, this.pageEl.setState({readyShow: true, loading: true}, this.pageEl.getListData));
    }

    compareFilterAndCampaignMarkets(filter, campaign) {
        const nonPrefixMarket = 'non' + campaign.identity.toLowerCase();
        const filterMarket = filter.market.toLowerCase();

        if(filterMarket === campaign.identity.toLowerCase())
            return true;

        if (filterMarket.includes('non')) {
            if (filterMarket !== nonPrefixMarket)
                return true;
        }

        return false;
    }

    setVariableOptionOpen = (filterType) => {
        const { variableOptionOpen } = this.state;
        this.setState({variableOptionOpen: variableOptionOpen === filterType ? '' : filterType });
    }

    switchStatTesting = (event) => {
        const checked = event.target.checked
        this.setState({ statTestingEnabled: checked })
        updateURLParams({"statTest": checked});        
        setLocalStorage('statTestingEnabled', checked)
    }

    setDataAvailability = (value) => {
        this.setState({ isDataAvailable: value })
    }

    openSelect = () => {
        const { filterOpen } = this.state;
        this.setState({filterOpen: !filterOpen})
    }

    selectFilter = e => {
        this.setState({
            currentOption: e,
            previousOption: e
        },this.pageEl.setState({readyShow: false, loading: true}, this.pageEl.getListData))
        let audienceType = initData.getModes().filter(obj=> obj.id === e)[0]?.name?.toUpperCase().replace(/ /g, "");
        updateURLParams({"audienceType": audienceType});
    }

    getModes = (pageName, selectedTabId) => {
        let { page } = this.props;
        let {selectedTab, prePostLineOption, lineOption} = this.state;
        page = pageName? pageName : page;
        selectedTab = selectedTabId? selectedTabId : selectedTab;
        let stab = (page === 'segments' || page === 'competitive' || page === 'global') && lineOption?.stab === 1;
        let selectedOption = (page === 'segments' || page === 'competitive' || page === 'global') && lineOption?.selectedOption;
        let modeOptions, disabledModes ;
        if(selectedTab === 3) {
            if(page === "advanced" && prePostLineOption?.stab !== 3){
                if(prePostLineOption?.stab === 1) disabledModes=[1];
                modeOptions = initData.getModes().filter(obj=> obj.id !== 2 && obj.id !== 3);
            }else{
                modeOptions = initData.getModes().filter(obj=> obj.id !== 3);
            }
        }else if(selectedTab === 4 && stab){
            if(selectedOption === 'recommend'){
                modeOptions = initData.getModes().filter(obj=> obj.id !== 2);
                disabledModes=[0,1]
            }else if(selectedOption === "familiarity" || selectedOption === "awareness"  ){
                modeOptions = initData.getModes().filter(obj=> obj.id !== 2 && obj.id !== 3);
                disabledModes = [1];
            }else{
                modeOptions = initData.getModes().filter(obj=> obj.id !== 2 && obj.id !== 3);
            }
        }else{
            modeOptions = initData.getModes().filter(obj=> obj.id !== 2 && obj.id !== 3);
        }
        return {modeOptions: modeOptions, disabledModes: disabledModes}
    }

    render() {
        const {page, history} = this.props;
        const {
            selectedTab,
            searchFilter,
            currentFilters,
            menuExpand,
            menuOpened,
            horizontalMenu,
            selectedBrand,
            selectedPctBrand,
            selectedPeriod,
            interval,
            startdate,
            enddate,
            pselectedPeriod,
            pstartdate,
            penddate,
            filterOn,
            readyShow,
            brandSsize,
            dateFilterId,
            segments,
            brands,
            segmentHidden,
            brandHidden,
            prePostLineOption,
            adminPanelStatusMessage,
            currentVariableGroup,
            currentVariableMainFilter,
            currentVariableFilter,
            variableOptionOpen,
            variableGroupOptions,
            variableFilterOptions,
            mainVariableFilterOptions,
            xAxisSelect,
            yAxisSelect,
            zAxisSelect,
            statTestingEnabled,
            isDataAvailable,
            currentOption,
            markets,
            selectedCampaign,
            loading,
            globalBrands,
            selectedGlobalBrand,
            selectedGlobalPctBrand,
            selectedCompetitiveBrands,
            selectedGlobalMarkets,
            hiddenListInBarChart,
            periodValue,
            intervalValue,
            summaryAPIRestrict,
            selectModeInterval,
            marketDataUpdated,
            downloadexport,
            lineOption,
            filtersData,
            expiredFilters,
            removedBrands,
            inValidORSharedSegments
        } = this.state;
        const tabs = page === 'summary' ? brands : page === 'user' || page === 'group' || page === 'brand' || page==='market' ? [] : initData.getTabs(page);
        const {id} = JSON.parse(localStorage.getItem('userInfo'));
        const statusMessageData = this.getStatusMessageText();
        const statusMessage = {
            status: statusMessageData.status?statusMessageData.status:'',
            message: typeof statusMessageData.message === 'function' ? statusMessageData.message(adminPanelStatusMessage.arg) :
            `${statusMessageData.message?statusMessageData.message:''}${adminPanelStatusMessage.arg}`,
        }
        let options = this.getModes()?.modeOptions;
        const disabledOption = this.getModes()?.disabledModes;
        const newFilters = {segments: [1, 5], competitive: [1,5], advanced: [0,1], global: [1,5]}
        let newCheck = true;
        if((newFilters[page] && newFilters[page].includes(selectedTab)) || page === 'markets') {
            newCheck = false;
        }
        const isAdminPanel = page==='users' || page === 'groups' || page === 'markets' || page === 'brands' || page === 'group' || page === 'contract' || page==='brand' || page==='user' || page ==='addbrand' || page ==='campaign' || page ==='addcontract' || page === 'adduser' || page === 'addgroup' || page === 'addbrand' || page === 'addbrand' 
        const defaultSideMenuEnabled = JSON.parse(getSessionStorage('sideMenu'));
        return (
            <div className="pageContainer">
                {inValidORSharedSegments?.length>0 && this.authorizedType === 'USER' &&  
                    <WarningModal 
                        inValidORSharedSegments = {inValidORSharedSegments} 
                        selectedCampaign = {selectedCampaign}
                        setSegmentsData = {this.setSegmentsData}
                        updateSharedSegments = {this.updateSharedSegments}
                        selectedGlobalBrand={selectedGlobalBrand[0]}
                        page = {page}
                    />
                }
                {loading && <LoadingLayer />}
                <Header
                    logoClicked={this.menuToggle.bind(this)}
                    title={initData.getMenuTitle(page === 'group'?'group' : (page === 'market' ? 'markets' : page))}
                    authorizedType={this.authorizedType}
                    SwitchSite={this.userType==='ADMIN'?this.switchSite.bind(this):null}
                    switchStatTesting={this.switchStatTesting}
                    statTestingEnabled={statTestingEnabled}
                />
                <div className={cx("contentContainer", {"expand": (menuExpand||defaultSideMenuEnabled ) })} style={{backgroundColor: isAdminPanel? "white": "#F5F7FA" }}>
                    <Menu menuClass={cx("leftContainer", {"openMenu": menuOpened })} authorizedType={this.authorizedType} currentPage={page === 'group'?'group':(page === 'advancedlanding'?'advanced':page)} menuExpand={menuExpand} selectedTab={selectedTab} onExpandClick={this.onExpandClick.bind(this)} onMenuClick={this.onMenuClick.bind(this)} onTabClick={this.onClickTabItem.bind(this)} />
                    <div className="rightContainer">
                        <div className={cx("pageContent", page + "Page", {"adminPage": this.authorizedType==='ADMIN'})} ref={e=>this.pagecontentEl=e}>
                            <div className={cx("mainContent", {"filterOpen" : page !== 'summary' && selectedTab>-1 && filterOn})}>
                            {this.authorizedType==='USER' && selectedTab > -1 &&
                                <HorizontalBar
                                    page={page}
                                    selectedTab={selectedTab}
                                    filterOn={filterOn}
                                    horizontalMenu={horizontalMenu}
                                    currentFilters={currentFilters}
                                    segments={segments}
                                    interval={interval}
                                    searchFilter={searchFilter}
                                    selectedBrand={page === 'global' ? selectedGlobalBrand : selectedBrand}
                                    selectedPctBrand={page === 'global' ? selectedGlobalPctBrand : selectedPctBrand}
                                    selectedCompetitiveBrands = {selectedCompetitiveBrands}
                                    selectedGlobalMarkets={selectedGlobalMarkets}
                                    brands={page === 'global' ? globalBrands : brands}
                                    primaryBrandHidden={page==='advanced' && selectedTab===1 }
                                    brandSsize = {brandSsize}
                                    selectedDate={{selectedPeriod: selectedPeriod, selectedInterval: interval, startdate: startdate, enddate: enddate, pselectedPeriod: pselectedPeriod, pstartdate: pstartdate, penddate: penddate}}
                                    onClickFilterFunction={page !== 'summary' && this.filterOpen}
                                    clearFiterFunction={page !== 'summary' && this.clearFiter.bind(this)}
                                    onChangePeriodFunction={this.onChangePeriod.bind(this)}
                                    onChangePostPeriodFunction={this.onChangePostPeriod.bind(this)}
                                    onSelectFunction={page === 'global' ? this.onSelectGlobalBrand : this.onSelectBrand.bind(this)}
                                    onSelectMarketFunction={this.onSelectMarkets.bind(this)}
                                    dateFilterId = {dateFilterId}
                                    lineOption={lineOption}
                                    prePostLineOption={prePostLineOption}
                                    currentVariableFilter={currentVariableFilter}
                                    mainVariableFilterOptions={mainVariableFilterOptions}
                                    currentVariableMainFilter={currentVariableMainFilter}
                                    variableGroupOptions={variableGroupOptions}
                                    currentVariableGroup={currentVariableGroup}
                                    xAxisSelect={xAxisSelect}
                                    yAxisSelect={yAxisSelect}
                                    zAxisSelect={zAxisSelect}
                                    isDataAvailable={isDataAvailable}
                                    useExposed={currentOption}
                                    campaigns={page !== 'global' && markets}
                                    selectedCampaign={selectedCampaign}
                                    onSelectCampaign={this.onSelectCampaign.bind(this)}
                                    newCheck = {newCheck}
                                    options = {options}
                                    tabs = {tabs}
                                    currentOption = {currentOption}
                                    selectFilter = {this.selectFilter.bind(this)}
                                    disabledOption = {disabledOption}
                                    userId={id}
                                    hiddenListInBarChart={selectedTab === 4 ?this.props.hiddenList:hiddenListInBarChart}
                                    pendoPathvalue ={ this.state.pendoPathvalue}
                                    onPeriodChange= {this.onPeriodChange}
                                    onIntervalChange= {this.onIntervalChange}
                                    onSelectModeChange={this.onSelectModeChange}
                                    wrapperRef = {this.wrapperRef}
                                    downloadPngPdfExports = {this.downloadPngPdfExports}
                                    data={marketDataUpdated}
                                    allFilters = {filtersData}
                                    statTestingEnabled = {statTestingEnabled}
                                    onPostPeriodDate = {this.onPostPeriodDate}
                                    getSelectedGlobalBrandMarkets = {this.getSelectedGlobalBrandMarkets}
                                /> }
                                {page !== 'summary' && selectedTab > -1  && tabs.length? <div className="newRow" style={{backgroundColor: isAdminPanel? "white": "#F5F7FA" }}>
                                {tabs.length > 0 &&
                                   <Tabs ref={e=>this.tabsList=e} tabs={tabs} isBrand={page === 'summary'}  currentTab={selectedTab} onClickTabItem={this.onClickTabItem.bind(this)} test={1} page={page} history={history} isAdminPanel={isAdminPanel}/> }
                                </div> : <div className={"tabsContainer"} style={{display: 'none'}}>
                                    <img src={compassLogoPng} className="compassLogoPng" style={{display: 'none'}} alt="compassLogoPng" />
                                </div>}
                            {(page === 'users') &&
                                <UsersManagementComponent {... this.props}
                                    ref={e=>this.pageEl=e}
                                    selectedTab={selectedTab}
                                    dataItemClick = {this.onMenuClick.bind(this)}
                                    history={history}
                                    adminPanelStatusMessage={adminPanelStatusMessage}
                                    showAdminPanelMessage={this.showAdminPanelMessage}
                                    hideAdminPanelMessage={this.hideAdminPanelMessage}
                                /> }
                                  {( page === 'groups') &&
                                <GroupManagementComponent {... this.props}
                                    ref={e=>this.pageEl=e}
                                    selectedTab={selectedTab}
                                    dataItemClick = {this.onMenuClick.bind(this)}
                                    history={history}
                                    adminPanelStatusMessage={adminPanelStatusMessage}
                                    showAdminPanelMessage={this.showAdminPanelMessage}
                                    hideAdminPanelMessage={this.hideAdminPanelMessage}
                                /> }
                            {page === 'user' &&
                                <UserProfile
                                    {...this.props}
                                    ref={e=>this.pageEl=e}
                                    history={history}
                                    showMessage={this.showAdminPanelMessage}
                                    userClick={this.onMenuClick.bind(this)}
                                    selectedTab={selectedTab}
                                    page={page}
                                />
                            }
                           {page === 'adduser' && <AddUserComponent history={history} showMessage={this.showAdminPanelMessage} errorMessage={this.errorMessage} />}
                            {page === 'addgroup' && <AddGroupComponent history={history} showMessage={this.showAdminPanelMessage} errorMessage={this.errorMessage} />}
                            {page === 'group' &&
                                <GroupProfile
                                    {...this.props}
                                    ref={e=>this.pageEl=e}
                                    history={history}
                                    showMessage={this.showAdminPanelMessage}
                                    selectedTab={selectedTab}
                                    userClick={this.onMenuClick.bind(this)}
                                    adminPanelStatusMessage={adminPanelStatusMessage}
                                    showAdminPanelMessage={this.showAdminPanelMessage}
                                    hideAdminPanelMessage={this.hideAdminPanelMessage}
                                    page={page}
                                    errorMessage={this.errorMessage}
                                />
                            }
                            {page === 'addcontract' && <CreateContractComponent {...this.props} history={history} showMessage={this.showAdminPanelMessage} />}
                            {page === 'contract' &&
                                <ContractProfile
                                    {...this.props}
                                    ref={e=>this.pageEl=e}
                                    history={history}
                                    showMessage={this.showAdminPanelMessage}
                                />
                            }
                            {page === 'brands' &&
                                <BrandsManagementComponent {... this.props}
                                    ref={e=>this.pageEl=e}
                                    selectedTab={selectedTab}
                                    history={history}
                                    showMessage={this.showAdminPanelMessage}
                                /> }
                            {page === 'addbrand' && <AddBrandComponent {...this.props} history={history} showMessage={this.showAdminPanelMessage} errorMessage={this.errorMessage} />}
                            {page === 'brand' &&
                                <BrandProfile
                                    {...this.props}
                                    ref={e=>this.pageEl=e}
                                    history={history}
                                    showMessage={this.showAdminPanelMessage}
                                />
                            }
                            {page === 'markets' &&
                                <CampaignManagementComponent {... this.props}
                                                             ref={e=>this.pageEl=e}
                                                             selectedTab={selectedTab}
                                                             history={history}
                                                             showMessage={this.showAdminPanelMessage}
                                /> }
                                {page === 'addmarket' && <AddCampaignComponent {...this.props} history={history} showMessage={this.showAdminPanelMessage} errorMessage={this.errorMessage}/>}
                                {page === 'addsurvey' && <AddDecipherSurveyComponent {...this.props} history={history} showMessage={this.showAdminPanelMessage} />}
                            {page === 'market' &&
                                <CampaignProfile
                                    {...this.props}
                                    ref={e=>this.pageEl=e}
                                    selectedTab={selectedTab}
                                    history={history}
                                    onCampaignEdit={this.onCampaignEdit}
                                    showMessage={this.showAdminPanelMessage}
                                    page={page}
                                />
                            }
                            {page === 'summary' &&
                                <BrandSummaryHomeComponent {... this.props}
                                    ref={e=>this.pageEl=e}
                                    searchFilter = {searchFilter}
                                    selectedTab={selectedBrand[0]}
                                    selectedBrand={selectedBrand[0]}
                                    dateFilterId = {dateFilterId}
                                    selectedDate={{selectedPeriod: selectedPeriod, startdate: startdate, enddate: enddate}}
                                    interval = {interval}
                                    brands={brands?.length > 0 ? brands:[]}
                                    onClickNextFunction={this.onMenuClick.bind(this)}
                                    readyShow = {readyShow}
                                    statTestingEnabled={statTestingEnabled}
                                    setDataAvailability={this.setDataAvailability}
                                    useExposed={currentOption}
                                    selectedCampaign={selectedCampaign}
                                    onChangePeriodFunction={this.onChangePeriod.bind(this)}
                                    onPeriodChange={this.onPeriodChange}
                                    onIntervalChange= {this.onIntervalChange}
                                    onSelectModeChange={this.onSelectModeChange}
                                    onMarketBrandChange={this.onMarketBrandChange}
                                    periodValue={periodValue}
                                    intervalValue={intervalValue}
                                    summaryAPIRestrict={summaryAPIRestrict}
                                    selectModeInterval={selectModeInterval}
                                />
                            }
                            {page === 'competitive' &&
                                <CompetitiveIntelligenceHomeComponent {... this.props}
                                    ref={e=>{this.pageEl=e}}
                                    searchFilter = {searchFilter}
                                    selectedTab={selectedTab}
                                    horizontalMenu = {horizontalMenu}
                                    brands={brands}
                                    brandHidden = {brandHidden}
                                    lineOption = {lineOption}
                                    onClickShowOrHide={(index, withoutRequest)=>this.onClickShowOrHide(index, 'brandHidden', withoutRequest)}
                                    onChangeLineOption={(option)=>this.onChangeLineOption(option, 'lineOption')}
                                    readyShow = {readyShow}
                                    selectedPeriod = {selectedPeriod}
                                    interval = {interval}
                                    startdate = {startdate}
                                    enddate = {enddate}
                                    onSelectVariableMainFilter={this.onSelectVariableMainFilter}
                                    onSelectVariableFilter={this.onSelectVariableFilter}
                                    onSelectVariableGroup={this.onSelectVariableGroup}
                                    currentVariableFilter={currentVariableFilter}
                                    currentVariableGroup={currentVariableGroup}
                                    currentVariableMainFilter={currentVariableMainFilter}
                                    mainVariableFilterOptions={mainVariableFilterOptions}
                                    variableGroupOptions={variableGroupOptions}
                                    variableFilterOptions={variableFilterOptions}
                                    variableOptionOpen={variableOptionOpen}
                                    setVariableOptionOpen={this.setVariableOptionOpen}
                                    filtersOn={filterOn}
                                    menuExpand={menuExpand}
                                    selectedDate={{selectedPeriod: selectedPeriod, startdate: startdate, enddate: enddate}}
                                    onChangePeriodFunction={this.onChangePeriod.bind(this)}
                                    main2x2FilterOptions={this.main2x2FilterOptions}
                                    current2x2MainFilter={this.state.current2x2MainFilter}
                                    option2x2Open={this.state.option2x2Open}
                                    set2x2OptionOpen={this.set2x2OptionOpen}
                                    onSelect2x2MainFilter={this.onSelect2x2MainFilter}
                                    axisSelectOptionChange={this.axisSelectOptionChange}
                                    xAxisSelect={xAxisSelect}
                                    yAxisSelect={yAxisSelect}
                                    zAxisSelect={zAxisSelect}
                                    statTestingEnabled={statTestingEnabled}
                                    setDataAvailability={this.setDataAvailability}
                                    useExposed={currentOption}
                                    selectedCampaign={selectedCampaign}
                                    dateFilterId={dateFilterId}
                                    onBrandsChange={this.onBrandsChange}
                                    hiddenList={this.hiddenList}
                                    page={page}
                                    setRef ={(ref) => this.wrapperRef = ref}
                                    downloadexport={downloadexport}
                                    segments={segments}
                                    getAppliedFilters={this.getAppliedFilters}
                                />
                            }
                            {page === 'segments' &&
                                <DetailedIntelligenceHomeComponent {... this.props}
                                    ref={e=>{this.pageEl=e}}
                                    searchFilter = {searchFilter}
                                    selectedTab={selectedTab}
                                    selectedBrand={selectedBrand[0]}
                                    brands={brands}
                                    segmentHidden = {segmentHidden}
                                    lineOption = {lineOption}
                                    horizontalMenu = {horizontalMenu}
                                    onClickFilter = {this.onClickFilter.bind(this)}
                                    onClickSegment = {this.onClickSegment.bind(this)}
                                    onClickShowOrHide={(index, withoutRequest)=>this.onClickShowOrHide(index, 'segmentHidden', withoutRequest)}
                                    onChangeLineOption={(option)=>this.onChangeLineOption(option, 'lineOption')}
                                    readyShow = {readyShow}
                                    selectedPeriod = {selectedPeriod}
                                    interval = {interval}
                                    startdate = {startdate}
                                    enddate = {enddate}
                                    onSelectVariableMainFilter={this.onSelectVariableMainFilter}
                                    onSelectVariableFilter={this.onSelectVariableFilter}
                                    onSelectVariableGroup={this.onSelectVariableGroup}
                                    currentVariableFilter={currentVariableFilter}
                                    currentVariableGroup={currentVariableGroup}
                                    currentVariableMainFilter={currentVariableMainFilter}
                                    mainVariableFilterOptions={mainVariableFilterOptions}
                                    variableGroupOptions={variableGroupOptions}
                                    variableFilterOptions={variableFilterOptions}
                                    variableOptionOpen={variableOptionOpen}
                                    setVariableOptionOpen={this.setVariableOptionOpen}
                                    onClickFilterFunction={page !== 'summary' && this.filterOpen}
                                    filtersOn={filterOn}
                                    menuExpand={menuExpand}
                                    main2x2FilterOptions={this.main2x2FilterOptions}
                                    current2x2MainFilter={this.state.current2x2MainFilter}
                                    option2x2Open={this.state.option2x2Open}
                                    set2x2OptionOpen={this.set2x2OptionOpen}
                                    onSelect2x2MainFilter={this.onSelect2x2MainFilter}
                                    axisSelectOptionChange={this.axisSelectOptionChange}
                                    xAxisSelect={xAxisSelect}
                                    yAxisSelect={yAxisSelect}
                                    zAxisSelect={zAxisSelect}
                                    statTestingEnabled={statTestingEnabled}
                                    setDataAvailability={this.setDataAvailability}
                                    showAdminPanelMessage={this.showAdminPanelMessage}
                                    useExposed={currentOption}
                                    selectedCampaign={selectedCampaign}
                                    onChangePeriodFunction={this.onChangePeriod.bind(this)}
                                    selectedDate={{selectedPeriod: selectedPeriod, startdate: startdate, enddate: enddate}}
                                    dateFilterId = {dateFilterId}
                                    getSegments={this.getSegments}
                                    hiddenList={this.hiddenList}
                                    setRef ={(ref) => this.wrapperRef = ref}
                                    downloadexport={downloadexport}
                                    segments={segments}
                                />
                            }
                             {page === 'global' &&
                                <GlobalDashboardHomeComponent {... this.props}
                                ref={e=>{this.pageEl=e}}
                                searchFilter = {searchFilter}
                                selectedTab={selectedTab}
                                selectedBrand={selectedGlobalBrand[0]}
                                horizontalMenu = {horizontalMenu}
                                brands={globalBrands}
                                brandHidden = {brandHidden}
                                lineOption = {lineOption}
                                onClickShowOrHide={(index, withoutRequest)=>this.onClickShowOrHide(index, 'globalHidden', withoutRequest)}
                                onChangeLineOption={(option)=>this.onChangeLineOption(option, 'lineOption')}
                                readyShow = {readyShow}
                                selectedPeriod = {selectedPeriod}
                                interval = {interval}
                                startdate = {startdate}
                                enddate = {enddate}
                                onSelectVariableMainFilter={this.onSelectVariableMainFilter}
                                onSelectVariableFilter={this.onSelectVariableFilter}
                                onSelectVariableGroup={this.onSelectVariableGroup}
                                currentVariableFilter={currentVariableFilter}
                                currentVariableGroup={currentVariableGroup}
                                currentVariableMainFilter={currentVariableMainFilter}
                                // variableSubfilterOptions={variableSubfilterOptions}
                                mainVariableFilterOptions={mainVariableFilterOptions}
                                variableGroupOptions={variableGroupOptions}
                                variableFilterOptions={variableFilterOptions}
                                variableOptionOpen={variableOptionOpen}
                                setVariableOptionOpen={this.setVariableOptionOpen}
                                filtersOn={filterOn}
                                menuExpand={menuExpand}
                                selectedDate={{selectedPeriod: selectedPeriod, startdate: startdate, enddate: enddate}}
                                onChangePeriodFunction={this.onChangePeriod.bind(this)}
                                main2x2FilterOptions={this.main2x2FilterOptions}
                                current2x2MainFilter={this.state.current2x2MainFilter}
                                option2x2Open={this.state.option2x2Open}
                                set2x2OptionOpen={this.set2x2OptionOpen}
                                onSelect2x2MainFilter={this.onSelect2x2MainFilter}
                                axisSelectOptionChange={this.axisSelectOptionChange}
                                xAxisSelect={xAxisSelect}
                                yAxisSelect={yAxisSelect}
                                zAxisSelect={zAxisSelect}
                                statTestingEnabled={statTestingEnabled}
                                setDataAvailability={this.setDataAvailability}
                                campaigns={markets}
                                useExposed={currentOption}
                                dateFilterId={dateFilterId}
                                hiddenList={this.hiddenList}
                                onGeosChange={this.onGeosChange}
                                getAppliedFilters={this.getAppliedFilters}
                                page={page}
                                setRef ={(ref) => this.wrapperRef = ref}
                                downloadexport={downloadexport}
                                segments={segments}
                                />
                            }
                            {page === 'advanced' &&
                                <AdvancedIntelligenceComponent {... this.props}
                                    ref={e=>{this.pageEl=e}}
                                    searchFilter = {searchFilter}
                                    selectedTab={selectedTab}
                                    selectedDate={{startdate: startdate, enddate: enddate, pstartdate: pstartdate, penddate: penddate, selectedPeriod: selectedPeriod}}
                                    selectedBrand={selectedBrand}
                                    selectedPctBrand={selectedPctBrand}
                                    brands={brands}
                                    readyShow = {readyShow}
                                    sendSsize = {(brandSsize) => this.setState({brandSsize})}
                                    onTabClick = {this.onClickTabItem.bind(this)}
                                    onChangeLineOption={(option)=>this.onChangeLineOption(option, 'prePostLineOption')}
                                    prePostLineOption={prePostLineOption}
                                    statTestingEnabled={statTestingEnabled}
                                    setDataAvailability={this.setDataAvailability}
                                    getAppliedFilters={this.getAppliedFilters}
                                    useExposed={currentOption}
                                    selectedCampaign={selectedCampaign}
                                    dateFilterId={dateFilterId}
                                    page={page}
                                    segments={segments}
                                    menuExpand={menuExpand}
                                />
                            }
                            {/* {page === 'quest-suite' && 
                                <ProductSuiteComponent {... this.props}
                                    ref={e=>this.pageEl=e}
                                    page = {page}
                                    setRef ={(ref) => this.wrapperRef = ref}
                                />
                            } */}
                            </div>
                        { (page === 'competitive' || page === 'segments' || page === 'advanced' || page === 'global') &&
                            <div ref={e=>{this.filtersDiv=e}} className={cx("filtersContainer", {"filterOpen" : selectedTab>-1 && filterOn})} >
                                <>
                                {page === 'segments' ?
                                    <FilterOptionComponent ref={e=>this.filtersEl=e} 
                                        getSegments={this.getSegments} 
                                        getFilters = {this.getFilters}
                                        selectedCampaign={selectedCampaign}
                                        segments={segments}
                                        page={page}
                                        allFilters = {filtersData}
                                        setSegmentsData = {this.setSegmentsData}
                                    /> :
                                    <FilterOptionComponent
                                        ref={e=>this.filtersSetting=e}
                                        getSegments={this.getSegments} 
                                        getFilters = {this.getFilters}
                                        segments={segments}
                                        page={page}
                                        selectedCampaign={selectedCampaign}
                                        applySearchFunction={this.applySearch.bind(this)}
                                        cancelFunction={this.clearFiter.bind(this, false)}
                                        selectedGlobalBrand={selectedGlobalBrand[0]}
                                        allFilters = {filtersData}
                                        setSegmentsData = {this.setSegmentsData}
                                        getAppliedFilters={this.getAppliedFilters}
                                    />
                                }
                                </>
                            </div>
                        }
                        { (page === 'competitive' || page === 'segments' || page==='global') && selectedTab<4 && <MiniBar menuExpand={menuExpand} horizontalMenu={horizontalMenu} onClickHorizontalMenuItem={this.onClickHorizontalMenuItem.bind(this)}/>}
                        <StatusMessage
                            opened={!!statusMessageData}
                            statusText={statusMessage.status}
                            message={`${statusMessage.message}`}
                            onClose={this.hideAdminPanelMessage}
                            error={this.state.error}
                            hideTimeout={10000}
                        />
                        </div>
                    </div>
                    <div className={cx("mobileOverlay", {"openMenu": menuOpened })}></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ trends }) => ({
    hiddenList: trends.hiddenList,
});

const mapDispatchToProps = (dispatch) => ({
    searchUser: (value) => dispatch({ type: ADMINSEARCH_TERM , payload: value }), 
    setCustomSegments:(value) => dispatch(setCustomSegments(value))
});


export default connect(mapStateToProps, mapDispatchToProps)(ContainerPage);