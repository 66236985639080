import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import cx from 'classnames';
import './FilterOptionComponent.scss';
import ApiData from '../../utils/Api';
import { useMutation } from "react-query";
import { IoIosSearch } from "react-icons/io";
import FilterGroup from "./OptionFilters/filterGroup";
import helpIcon from '../../assets/icons/help_icon.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateSegment from './OptionFilters/CreateSegment';
import DeleteSegment from './OptionFilters/DeleteSegment';
import CustomSegments from './OptionFilters/CustomSegments';
import filtersTransformations from '../../utils/filterTransformations';
import { getLocalStorage, getSessionStorage, setLocalStorage, setSessionStorage } from '../../utils/storageHelpers';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ClipLoader from "react-spinners/ClipLoader";
import { IoWarningOutline } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { updateURLParams } from '../../utils/URLParamsValidations';

const pendo = window.pendo;

const FilterOptionComponent = React.forwardRef((props, ref) => {

    let { page , selectedCampaign, selectedGlobalBrand, allFilters } = props;
    let [ filters, setFilters ] = useState([]);
    let [ currentFilters, setCurrentFilters ] = useState([]);
    let [ segments, setSegments ] = useState([]);
    let [ searchKey, setSearchKey ] = useState();
    let [ selectedSegment, setSelectedSegment ] = useState();
    let [ step, setStep ] = useState(0);
    let [ selectedFiltersCount, setSelectedFiltersCount ] = useState(0);
    let [ showActionsModal, setShowActionModal ] = useState(false);
    let [ suggestionsList, setSuggestionsList ] = useState([]);
    let [ searchResult, setSearchResult ] = useState([]);
    let [ searchList, setSearchList] = useState([]);
    let [ categoryCheck , setCategoryCheck] = useState(false)
    let [ selectedAction, setselectedAction ] = useState();
    let [ selectedSegementsCount, setSelectedSegementsCount ] = useState();
    let [ totalSegmentsCount, settotalSegmentsCount ] = useState();
    let [ deleteCategoryName, setdeleteCategoryName ] = useState();
    let [ isSaveButtonEnabled, setIsSaveButtonEnabled ] = useState(false);
    let [ selectedDeleteCategory, setSelectedDeleteCategory ] = useState([]);
    let [ applyButtonDisabled ,setApplyButtonDisabled ] = useState(true);
    let [ applyFilters , setApplyFilters] = useState([]);
    let [ isLoading, setIsLoading ] = useState(false);
    let [ segmentsLimit, setSegmentsLimit ] = useState(false);
    let [ deleteAllSegments , setDeleteAllSegments] = useState(false);
    let actionsRef = useRef();
    let actionsModalref = useRef();
    let createSegmentRef = useRef();
    let suggestionModalRef = useRef();
    let isApplyFiltersList = Boolean(JSON.parse(getSessionStorage('applyFilters'))?.find(({categoryId})=>!categoryId));

    let deleteSegment = useMutation(ApiData.deleteUserSegment, {
        onSuccess: async () => {
            await props.setSegmentsData({}, selectedSegment.segmentId);
            isApplyFiltersList && await filtersTransformations.applyFilters(selectedSegment.segmentId,page,'delete',props.applySearchFunction);
            handleCancel();
            setLoading(false);
        },
        onError: async (error) => {
            console.log(error);
            setLoading(false);
        }
    });

    let deleteCategory = useMutation(ApiData.deleteCategory, {
        onSuccess: async () => {
            await props.getSegments();
            let findCategoryIndex = segments?.findIndex(obj=>obj.categoryId == selectedDeleteCategory);
            if(deleteAllSegments && findCategoryIndex > -1){
                segments?.length > 0 && segments.splice(findCategoryIndex, 1);
                props.setSegmentsData(segments);
            }
            await handleCancel();
            setLoading(false);
        },
        onError: async (error) => {
            console.log(error);
            setLoading(false);
        }
    });

    let updateCategoryOrder = useMutation(ApiData.updateCategoryOrder, {
        onSuccess: async () => {
         await props.setSegmentsData(segments);
        },
        onError: async (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        })
    })

    useEffect(() => {
        setFilters(formatFilters());
    }, [])

    useEffect(() => {
        setselectedAction();
        setSelectedFiltersCount(0);
        setStep(0);
        setSelectedSegementsCount(0)
        currentFilters.length> 0 && setCurrentFilters([]);
        setApplyFilters([]);
        setApplyButtonDisabled(true);
        currentFilters.length> 0 && setSessionStorage('applyFilters',JSON.stringify([]))
    }, [page])

    useEffect(() => {
        calcSegmentsCategoryCount(JSON.parse(getSessionStorage('segmentsList')));
        restrictionSegments(JSON.parse(getSessionStorage('segmentsList')))  
        props.segments?.length > 0 && setSegments(JSON.parse(getSessionStorage('segmentsList')));
        page === 'segments' && step === 0 && setSearchList(buildSegmentsforSearch( props.segments));
    }, [props.segments,selectedCampaign])

    useEffect(() => {
        setSearchKey('');
        setSearchResult([]);
        setSearchList([]);
        setSuggestionsList([]);
        setFiltersData();
        calcSegmentsCategoryCount(JSON.parse(getSessionStorage('segmentsList')));
        setApplyButtonDisabled(true);
    }, [step, page, allFilters, selectedCampaign])

    useEffect(()=>{
        page !== 'segments' && currentFilters.length != 0 && changeCurrentFilters(currentFilters);
    },[selectedCampaign, selectedGlobalBrand])

    useImperativeHandle(ref, () => ({
        resetFilters(reset) {
            resetFilters(reset);
        },
        handleSegmentsActions(action,segmentId){
            handleSegmentsActions(action,segmentId);
        },
        createNewSegment(){
            createNewSegment();
        },
        changeCurrentFilters(currentFilters, step){
            changeCurrentFilters(currentFilters, step)
        },
    }))
    const activeDeactiveSegments = useMutation(ApiData.enableDisableSegment, {
        onSuccess: async () => {
        },
        onError: async (error) => {
            console.log(error);
        }
    });
    const restrictionSegments =async (segmentsList) => {
        let count =  calcSegmentsCategoryCount(segmentsList);
        let segmentsCount = 0;
    if(count > 20 ){
        segmentsList.reduce((acc, {segments}) =>{
            segments.filter(obj=>obj.segmentMarkets.includes(props.selectedCampaign?.marketName) === true);
            segments.map(obj=>{
            if( obj.isActive === true && (obj.segmentName !=="Age 18+" || obj.segmentName !=="Age 35+")){
               if( segmentsCount < 19 ){
                segmentsCount = segmentsCount + 1;
               }
               else{
                obj.isActive = false ;
            }
            }
        } )
        })
       await setSessionStorage('segmentsList',JSON.stringify(segmentsList))
       await calcSegmentsCategoryCount(segmentsList);
       let segments = segmentsList.reduce((acc, sr) => {
        acc.push(...sr.segments);
        return acc;
    }, [])
    let segmentData = [];
    segments.forEach((item) => {
        segmentData.push({
            segmentId: item?.segmentId,
            isActive: item.isActive,
        })
    })
       activeDeactiveSegments.mutate(segmentData)
    }   
    }

    const calcSegmentsCategoryCount = (segmentsList) =>{
        let countsegments = segmentsList?.reduce((accu, segments) => {
            return accu + segments?.segments?.reduce((acc, obj) => acc + (obj?.isActive === true && (obj?.segmentMarkets?.includes(selectedCampaign?.marketName) === true)), 0)
        }, 0)
         
        let segmentsCount = segmentsList?.reduce((count, obj) => count + obj?.segments?.length, 0);
        setSelectedSegementsCount(countsegments)
        settotalSegmentsCount(segmentsCount)
        return countsegments
    }

    const reorderCategory = (categoryData, startIndex, endIndex) => {
        const result = Array.from(categoryData);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEndCategory = async (result) => {
        if (!result.destination) {
            return;
        }
        const activearr = reorderCategory(
            segments,
            result.source.index,
            result.destination.index
        );

        const data = await activearr.map((b, i) => {
            b.position = i + 0;
            return b;
        });
        setSegments(data)
        let categoryPayload = []
        data.forEach(item => {
            categoryPayload.push({
                categoryId: item?.categoryId,
                position: item.position,
            })
        })
        await updateCategoryOrder.mutate(categoryPayload)
    }

    const setFiltersData = () => {
        let filtersData = JSON.parse(getLocalStorage('filters'));
        if (page === 'segments' && step === 0) {
            setSearchList(buildSegmentsforSearch(segments));
        } else if (page !== 'segments' && step !== 0) {
            let filterList = filtersData.filter(ele => ele.categoryId);
            setFilters(filterList);
            setSearchList(buildFiltersforSearch(filterList));
        } else {
            setFilters(formatFilters());
            setSearchList(buildFiltersforSearch(filtersData));
        }
    }

    const formatFilters = () => {
        let filtersData = JSON.parse(getLocalStorage('filters'));
        let segmentsList = JSON.parse(getSessionStorage('segmentsList'));

        let findSegmentsIndex = filtersData?.findIndex(ele => ele.categoryId === null);
        let data = filtersData?.find(ele => !ele.categoryId)?.filterResponse;
        let temp = {};
        if (data) {
            for (let e of data) {
                !temp[e.filterGroup] ? temp[e.filterGroup] = [e] : temp[e.filterGroup].push(e)
            }
            if (findSegmentsIndex > -1){
                filtersData[findSegmentsIndex].filterResponse = Object.values(temp);
                filtersData[findSegmentsIndex].filterResponse?.length>0 && 
                filtersData[findSegmentsIndex].filterResponse.sort((a, b) => {
                    const orderA = segmentsList?.findIndex(item => (item?.categoryName === a[0].filterGroup));
                    const orderB = segmentsList?.findIndex(item => (item?.categoryName === b[0].filterGroup));
                    return orderA - orderB;
                });
            }
        }
        return filtersData;
    }

    const handleClickOutside = (event) => {
        if (actionsRef.current && actionsRef.current.contains(event.target)) {
            return null;
        } else if (actionsModalref.current && !actionsModalref.current.contains(event.target)) {
            showActions();
        }
        if (suggestionModalRef.current && suggestionModalRef.current.contains(event.target)) {
            return null;
        } else if (suggestionModalRef.current && !suggestionModalRef.current.contains(event.target)) {
            setSuggestionsList([]);
        }
    }

    const createNewSegment = async () => {
        await setStep(page === 'segments'? 1:2);
        changeCurrentFilters(currentFilters.filter(obj => obj.categoryId));
    }

    const resetFilters = (reset) => {
        setSessionStorage('applyFilters',JSON.stringify([]));
        setCurrentFilters([]); 
        changeCurrentFilters([]);
        updateURLParams({},['segments','filters']);
        if (props.cancelFunction && !reset) {
            props.cancelFunction();
        }
    }

    const buildFiltersforSearch = (filters) => {
        const sfilters = filters?.map((fg, index) => { return { index: index, name: fg.categoryName.toLowerCase() } });
        if (sfilters?.length > 0) {
            sfilters.push({ index: 1, name: "range" });

            filters.forEach((filter, ind) => {
                filter.filterResponse.forEach(f => {
                    if (Array.isArray(f)) {
                        f.forEach(f => sfilters.push({ index: ind, name: f.filterName?.replace(/_/g, ' ').toLowerCase() }))
                    } else {
                        sfilters.push({ index: ind, name: f.filterName?.replace(/_/g, ' ').toLowerCase() });
                        if (f && f?.filterOptions && f?.filterOptions.length > 0) {
                            f.filterOptions.forEach((fo) => {
                                sfilters.push({ index: ind, name: fo.filterOptionName?.replace(/_/g, ' ').toLowerCase() });
                            });
                        }
                    }
                });
            })
        }
        return sfilters;
    }

    const buildSegmentsforSearch = (categoryData) => {
        const searchSegmentsList = categoryData?.map((obj, index) => { return { index: index, name: obj.categoryName?.toLowerCase() } });
        if (searchSegmentsList?.length > 0) {
           categoryData && categoryData.forEach(({segments}, ind) => {
                segments && segments.forEach(f => {
                    searchSegmentsList.push({ index: ind, name: f.segmentName?.replace(/_/g, ' ').toLowerCase() });
                });
            })
        }
        return searchSegmentsList;
    }

    const onSearchChange = (e) => {
        let suggestionsList = [];
        if (e.target.value.trim().length > 0) {
            suggestionsList = searchList.filter((f) => { return f.name?.indexOf(e.target.value.trim().toLowerCase()) > -1 });
        }
        setSearchKey(e.target.value);
        setSuggestionsList(suggestionsList);
        onFilterSearch(e.target.value);
    }

    const handleKeyUp = e => {
        if (e.keyCode === 13) {
            onFilterSearch(e.target.value);
        }
    }

    const clearSearch = () => {
        setSuggestionsList([]);
        setSearchKey('');
        setSearchResult([]);
    }

    const onFilterSearch = (skey, suggestion) => {
        let searchResult = [];
        if (skey.trim().length > 0) {
            searchResult = searchList.filter((f) => { return f.name?.indexOf(skey.trim().toLowerCase()) > -1 });
        }
        setSearchKey(skey);
        setSearchResult(searchResult);
        suggestion && setSuggestionsList([]);
    }

    const changeCurrentFilters = (selectedcurrentFilters, stepValue) => {
        let selectedStep = stepValue>-1 ? stepValue : step;
        let filtersData = JSON.parse(getLocalStorage('filters')); 
        let count = selectedcurrentFilters?.reduce((accu, categoryObj) => {
            return accu + categoryObj?.filters?.reduce((acc, obj) =>{ 
            let findObj = filtersData.find(({filterResponse})=> filterResponse.find(ele=>{
                const filtersPresentInMarket = filtersTransformations.checkFilterPresentInMarket(page, ele.filterMarket, selectedCampaign?.marketName, selectedGlobalBrand);
                return (ele.filterId === obj.filterId && ((!selectedStep && filtersPresentInMarket )||(selectedStep && !ele.isExpired)))
            }))
            return acc + ( findObj ? (obj?.filterId === 8 ? 1: obj?.filterOptions?.length):0)}, 0)
        }, 0)

        count>0 && setApplyButtonDisabled(false);
        setSelectedFiltersCount(count);
        setCurrentFilters(selectedcurrentFilters);
    }

    const handleApplyFilters = () => {
        pendoFiltersTrackEvents();
        setShowActionModal(false);
        if (props.cancelFunction && props.applySearchFunction) {
            setApplyButtonDisabled(true); 
            setSessionStorage('applyFilters',JSON.stringify(currentFilters))
            setApplyFilters(JSON.parse(getSessionStorage('applyFilters')))
            props.applySearchFunction(currentFilters);
        }
    }

    const showActions = () => {
        setShowActionModal(!showActionsModal);
    }

    const handleSaveButtonEnabled = (isSaveEnabled) =>{
        setIsSaveButtonEnabled(isSaveEnabled);
    }

    const handleActiveSegmentsLimited = (segmentsLimitEnable) =>{
        setSegmentsLimit(segmentsLimitEnable);
        setTimeout(()=>{
            setSegmentsLimit(false)
        },5000);
    }

    const handleMaxSegmentsLimited = () =>{
        setTimeout(()=>{
            setselectedAction();
        },5000);
    }

    const setLoading = (loading) =>{
        setIsLoading(loading)
    } 

    const createOrEditSegment = () => {
        setLoading(true);
        createSegmentRef && createSegmentRef.current.createOrEditSegment();
        selectedSegment && setSelectedSegment();
    }

    const handleSegmentsActions = async (action, id) => {
        setselectedAction(action[0].toUpperCase() + action.slice(1));
        setCurrentFilters([]);
        let selectedSegment = [];
        segments.forEach(ele => {
            let selectedSegmentObj = ele.segments.find(({ segmentId }) => segmentId == id);
            if (selectedSegmentObj) {
                selectedSegmentObj.categoryId = ele.categoryId;
                selectedSegmentObj.categoryName = ele.categoryName;
                selectedSegment.push(selectedSegmentObj);
            }
        });
        let formatCurrentFilters = filtersTransformations.formatSelectedSegmentsFilters(selectedSegment[0]?.filters,action)
        if (action === 'edit') {
            await setStep(1);
            changeCurrentFilters(formatCurrentFilters, 1);
        } else if(action === 'copy') {
            if(totalSegmentsCount < 50){
                await setStep(1);
                changeCurrentFilters(formatCurrentFilters, 1);
            }else handleMaxSegmentsLimited();
        } else {
            setStep(3);
        }
        setSelectedSegment(selectedSegment[0]);
        setCurrentFilters(formatCurrentFilters);
    }

    const handleCancel = () => {
        let applyfiltersList = JSON.parse(getSessionStorage('applyFilters'))
        setStep(0);
        changeCurrentFilters(applyfiltersList?.length>0?applyfiltersList:[], 0)
        isApplyFiltersList && props.applySearchFunction();
        setselectedAction();
        setSelectedSegment();
        setCategoryCheck(false);
    }

    const onClickPageChange = async (step) => {
        let applyfiltersList = JSON.parse(getSessionStorage('applyFilters'))
        await setFiltersData();
        setStep(step);
        setShowActionModal(false);
        if (step === 0) {
            await changeCurrentFilters(applyfiltersList?.length>0?applyfiltersList:[],0);
            applyfiltersList?.length> 0 && props.applySearchFunction();
            setSelectedSegment();
            setselectedAction();
            setApplyButtonDisabled(true);
        }
    }
    // Callback function for getting all segments delete selection
    const onDeleteAllSegmentsInCategory = ( status) =>{
        setDeleteAllSegments(status)
    }
    const DeleteCategoryOrSegment = async () => {
        setLoading(true);
        let payload = {
            deleteAllSegmentsInCategory: deleteAllSegments
        }
        {
            !categoryCheck ? await deleteSegment.mutate(selectedSegment?.segmentId) :
            await deleteCategory.mutate({categoryId:selectedDeleteCategory, payload})
            setDeleteAllSegments(false)
        }
    }
    const changeSegments = async(categoryList ) => {
        await setSegments(categoryList)
        calcSegmentsCategoryCount(categoryList)
        setSessionStorage('segmentsList',JSON.stringify(categoryList));
    }

    const handleDeleteCategoryActions = async (action, id, name) => {
        setselectedAction(action[0].toUpperCase() + action.slice(1));
        if (action === 'delete') {
            setCategoryCheck(!categoryCheck)
            setdeleteCategoryName(name)
            await setStep(3);
            setSelectedDeleteCategory(id)
        }
    }

   const  pendoFiltersTrackEvents = () => {
        let pendoFilterOptions = []; 
        currentFilters && currentFilters.forEach(category => {
            category.filters.forEach(filter => {
                filter.filterOptions && filter.filterOptions.forEach(obj => {
                     pendoFilterOptions.push({                
                        "filterCategory": category?.categoryName,
                        "filterGroup ": filter?.filterName,
                        "filterLabel": obj?.filterOptionName,
                    });
                })
            })
        })

        pendoFilterOptions.forEach(pendoFilter => {
        if(pendo !== undefined){
            pendo.track("Filter Selected", {
                "filterCategory": pendoFilter?.filterCategory,
                "filterGroup ": pendoFilter?.filterGroup,
                "filterLabel": pendoFilter?.filterLabel,
            });
        }
        })
    }

    let createButtonEnabled = !(currentFilters.filter(ele=>ele.categoryId !== null)?.length);
    return (
        <>
            <div className='filterOptionContainer'>
                {/* Header */}
                <>
                    <div className='segmentTitleContainer'>
                        <div>
                            {step === 0 &&
                                <>
                                    <div className="segmentHeader" >{page === 'segments' ? 'Custom Segments' : 'Filters'}</div>
                                    {step === 0  && page === 'segments' && <div className='segmentTitle'>{selectedSegementsCount}/{totalSegmentsCount} Segments Selected</div>}
                                </>
                            }
                            {step !== 0 && <div className="segmentHeader" > {selectedAction !== 'Copy' && selectedAction ? selectedAction : 'Create'} {!categoryCheck ? 'Segment' : 'Category'}{selectedAction === 'Copy' && ' (Copy)'}</div>}
                            {step !== 3 ? 
                                <>
                                    {selectedFiltersCount === 0 && (step !== 0 || page !== 'segments') && <div className='segmentTitle'>Select Filters</div>}
                                    {selectedFiltersCount > 0 && <div className='segmentTitle'>{selectedFiltersCount} Filters Selected</div>}
                                </>
                                :
                                <div className='segmentTitle'>Are you sure?</div>
                            }
                        </div>
                        {/* <div className='helpIcon'>
                            <img src={helpIcon} alt="" width={22} height={22} />
                            <span className={'tooltipWarning'}></span>
                        </div> */}
                    </div>
                    {(step === 0 || step === 1) &&
                        <div className={cx("filtersGroup", { "searchResult": (searchResult && searchKey) })}>
                            <div className="searchContainer" ref={suggestionModalRef}>
                                <div className="selectFilterContainer" >
                                    <IoIosSearch className="prefixIcon"/>
                                    <input type="text"
                                        placeholder="Search"
                                        value={searchKey}
                                        className="textarea"
                                        onChange={(e) => onSearchChange(e)}
                                        onKeyUp={(e) => handleKeyUp(e)}
                                    />
                                    {searchKey && <RxCrossCircled className="prefixIcon cursor"  onClick={clearSearch}/>}
                                    {suggestionsList?.length > 0 &&
                                        <div className="suggestionsList" >
                                            {suggestionsList?.map(s=>{
                                                return <div key={s.id} className="sItem" onClick={()=>onFilterSearch(s.name,true)}>{s.name}</div>
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                   
                </>
                {(step === 0) && segmentsLimit &&
                    <div className="searchContainer">
                        <div className="segmentLimitContainer" >
                            <IoWarningOutline className="segmentsLimitIcon" />
                            <input type="text"
                                value='Only 20 Segments are allowed'
                                className="textarea"
                                style={{ color: 'coral', width: '185px' }}
                            />
                        </div>
                    </div>
                }
                {(step === 0) && totalSegmentsCount > 49 && selectedAction === 'Copy' &&
                    <div className="searchContainer">
                    <div className="segmentLimitContainer" >
                        <IoWarningOutline className="segmentsLimitIcon" />
                        <textarea
                            value='You have reached the maximum segments limit'
                            className="textarea"
                            style={{ color: 'coral', width: '185px' , resize: 'none', border: 'none'}}
                        />
                    </div>
                </div>
                }
                
                {/* Display filters and segments */}
                <>
                    {step === 0 && page === 'segments' &&
                        <div className={cx("filtersGroup", { "searchResult": (searchResult && searchKey) })}>
                            <DragDropContext onDragEnd={onDragEndCategory}>
                                <Droppable droppableId="droppable" direction="vertical">
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef}
                                            {...provided.droppableProps}>
                                            {segments?.map((obj, index) => {
                                                segments.forEach(ele=>{
                                                    let categoryTest = ele?.segments?.every((obj) => obj.isActive)
                                                    ele.isCheck = categoryTest
                                                })
                                                if(obj.categoryType === 'DEFAULT' && selectedCampaign?.countryCode === 'HSS'){
                                                    obj.segments[0].segmentName = 'Age 35+';
                                                }
                                                return (
                                                    <Draggable
                                                        key={obj.categoryId}
                                                        index={index}
                                                        draggableId={`${obj.categoryId}`}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                            >
                                                                <>
                                                                    <CustomSegments
                                                                        key={'fg_' + index}
                                                                        searchFound={searchResult.findIndex((s) => {
                                                                            return s.index === index
                                                                        }) > -1}
                                                                        searchKey={searchKey}
                                                                        title={obj.categoryName}
                                                                        segmentResponse={obj.segments}
                                                                        categoryData={[obj]}
                                                                        categoryList={segments}
                                                                        page={page}
                                                                        onSegments={changeSegments}
                                                                        handleDeleteCategoryActions={handleDeleteCategoryActions}
                                                                        handleSegmentsActions={handleSegmentsActions}
                                                                        handleDrag={provided.dragHandleProps}
                                                                        selectedSegementsCount={selectedSegementsCount}
                                                                        selectedMarket={selectedCampaign}
                                                                        setSegmentsData={props.setSegmentsData}
                                                                        getSegments={props.getSegments}
                                                                        calcSegmentsCategoryCount = { calcSegmentsCategoryCount}
                                                                        handleSegmentsLimited ={handleActiveSegmentsLimited}
                                                                    />
                                                                </>
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Draggable>

                                                )
                                            })
                                            }
                                            {provided.placeholder}
                                        </div >
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    }
                    {((step === 0 && page !== 'segments') || step === 1) &&
                        <div className={cx("filtersGroup", { "searchResult": (searchResult && searchKey) })}>
                            {filters?.length>0 && filters.map((filtersObj, ind) => {                                
                                const isExpiredFilterPresent = Boolean(currentFilters?.find(obj => {
                                     return obj.filters.find(filter => filtersObj.filterResponse.find(({filterId})=>filterId === filter.filterId && filter.isExpired === true))
                                }))
                                let everyFilterIsExpired = filtersObj.filterResponse.every(obj => obj.isExpired === true);
                                //Hidden Category which is having only one filter and it is expired and not selected for any segment 
                                let filterResponse = (!isExpiredFilterPresent && everyFilterIsExpired) ? []:filtersObj.filterResponse;

                                if(searchKey?.length> 0){
                                    filterResponse =  filterResponse.filter((ele)=>{
                                        let segmentsMatchFound, categoryNameMatchFound, filterNameMatchFound, filterOptionsMatchFound;
                                        if(Array.isArray(ele)){
                                            segmentsMatchFound = ele?.findIndex(({filterName,filterGroup})=>filterName?.toLowerCase()?.includes(searchKey?.toLowerCase())||filterGroup?.toLowerCase()?.includes(searchKey?.toLowerCase()))
                                        }else{
                                            categoryNameMatchFound = filtersObj.categoryName?.toLowerCase()?.includes(searchKey?.toLowerCase())
                                            filterNameMatchFound = ele.filterName?.toLowerCase()?.includes(searchKey?.toLowerCase())
                                            filterOptionsMatchFound = ele?.filterOptions?.findIndex(({filterOptionName})=>filterOptionName?.toLowerCase()?.includes(searchKey?.toLowerCase()))
                                        }
                                        if(categoryNameMatchFound || filterNameMatchFound || filterOptionsMatchFound>-1 || segmentsMatchFound>-1){
                                            return ele;
                                        }
                                    })
                                }
                                return (
                                    <FilterGroup
                                        key={'fg_' + ind}
                                        searchFound={searchResult.findIndex((s) => {
                                            return s.index === ind
                                        }) > -1}
                                        searchKey={searchKey}
                                        title={filtersObj.categoryName}
                                        categoryObj={filtersObj}
                                        onFilterChange={changeCurrentFilters}
                                        currentSelectedFilters={currentFilters}
                                        filters={filterResponse}
                                        page={page}
                                        allFilters = {filters}
                                        selectedMarket={selectedCampaign}
                                        selectedGlobalBrand={selectedGlobalBrand}
                                        handleSegmentsActions={handleSegmentsActions}
                                    />
                                )
                            })}
                        </div>
                    }
                    {step === 2 &&
                        <CreateSegment
                            ref={createSegmentRef}
                            categoryList={segments} 
                            selectedSegment={selectedSegment} 
                            currentFilters={currentFilters} 
                            onClickPageChange={onClickPageChange} 
                            changeCurrentFilters={changeCurrentFilters} 
                            handleSaveButtonEnabled={handleSaveButtonEnabled}
                            setLoading={setLoading}
                            propsData={props} 
                            ref1={suggestionModalRef}
                            selectedMarket={selectedCampaign}
                            selectedAction={selectedAction}
                        />
                    }
                    {step === 3 &&
                        <DeleteSegment
                            currentFilters={currentFilters} 
                            changeCurrentFilters={changeCurrentFilters} 
                            selectedSegment={selectedSegment} 
                            categoryCheck={categoryCheck} 
                            deleteCategoryName={deleteCategoryName} 
                            onDeleteAllSegmentsInCategory={onDeleteAllSegmentsInCategory}
                        />
                    }
                </>
            </div>
            {/* footer */}
            <div className='bottomButtons'>
                {(step === 0 && page !== 'segments') && <>
                    <div className='button whiteButton' onClick={()=>resetFilters(false)}>{currentFilters?.length > 0 ? 'Clear' : 'Cancel'}</div>
                    <div className={cx("button blueButton", { "disabled": applyButtonDisabled })} style={{pointerEvents: "all"}}>
                        <div style={{padding:'14px 20px'}} onClick={()=>!applyButtonDisabled && handleApplyFilters()}>Apply</div>
                        <ExpandMoreIcon style={{padding:'13px 6px'}} className={cx("expandIcon", { "active": showActionsModal })} alt="" onClick={showActions} ref={actionsRef} />
                    </div>
                    {showActionsModal &&
                        <div className='buttonConatiner' ref={actionsModalref}>
                            <div className= {cx("buttonText",{"disabled": (totalSegmentsCount > 49 || createButtonEnabled)})} onClick={createNewSegment}>Create Segment</div>
                            <div className={cx('buttonText',{ "disabled": applyButtonDisabled })} onClick={()=>handleApplyFilters()}>Apply Filters</div>
                        </div>
                    }
                </>}
                {step === 0 && page === 'segments' && <div className={cx("blueButton create ", { "disabled": totalSegmentsCount > 49 })} onClick={createNewSegment}>Create a Segment</div>}
                {step === 1 && <><div className='button whiteButton' onClick={handleCancel}>Cancel</div><div className={cx("button blueButton", { "disabled":  selectedFiltersCount < 1 })} onClick={() => setStep(2)}>Review</div></>}
                {step === 2 && <><div className='button whiteButton' onClick={()=>onClickPageChange(1)}>Previous</div><div className={cx("button blueButton",{"disabled": (!isSaveButtonEnabled || isLoading)})} onClick={createOrEditSegment}>
                    {isLoading ?<><ClipLoader  color="#ffff" size={20}/> Save</>:'Save'}
                </div></>}
                {step === 3 && <><div className='button whiteButton' onClick={handleCancel}>Cancel</div><div className={cx("button redButton",{"disabled": isLoading})} onClick={DeleteCategoryOrSegment}>{!categoryCheck ? 
                    isLoading ? <><ClipLoader  color="#ffff" size={20}/> Delete Segment</>:'Delete Segment': 'Delete Category'}
                </div></>}
            </div>
        </>
    )
}
)

export default FilterOptionComponent;