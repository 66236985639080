import React, { Component } from 'react';
import './BrandTitle.scss';
import PropTypes from 'prop-types';


export default class brandTitle extends Component {
    render() {
        const {
          brand,
          charKeyMap,
          customClass,
          noData,
          noLogo,
          ssize,
          statTestingEnabled,
          onClickItemFunction,
        } = this.props;

        const brandName = brand.displayName
          ? brand.displayName : brand.name
            ? brand.name : brand.decipherName
              ? brand.decipherName : '';
        const logo = !noLogo && ((brand.logo)? <img src={brand.logo} className="brandLogoIcon" alt="logo" />:
                   (<div className="brandDefaultLogo">
                        <div>{brandName.slice(0, 1).toUpperCase()}</div>
                    </div>));
        const brandClass= "brandTitle" + (customClass? " "+customClass:"");
        return (
            <div className={brandClass} onClick={(typeof onClickItemFunction)==='function'?()=>onClickItemFunction(brand.id):null}>
                {logo}
                <div className="brandName">
                    <div className={'titleBlock'}>
                        <div className={'title'}>{`${brandName}`}</div>
                        <div className={'subtitle'}>{`Brand ID: ${brand.id}`}</div>
                    </div>
                        {Number(ssize)>0 && <div className="sampleSize">n={Number(ssize.toFixed(0)).toLocaleString('en-En')}</div>}
                        {noData && <div className="noData">No data available</div>}
                    {statTestingEnabled && (
                        <div className="statLetter">
                            <span>{charKeyMap[brand.name]}</span>
                            {brand.externalId}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
brandTitle.propTypes = {
    noData: PropTypes.bool,
}
brandTitle.defaultProps = {
    noData: false,
}
